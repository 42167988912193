import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'

const BuiltByUs = () => {

useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Built By Us | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
        <Helmet>
            <title>Built By Us | Ra'Asis</title>
            <meta name="description" content="Our innovative creations empower startups & upcoming brands. Explore some of our recent work. Get an insight on which solution will perfectly align with your brand's needs." />
            <meta property="og:title" content="Built By Us | Ra'Asis" />
            <meta property="og:description" content="Our innovative creations empower startups & upcoming brands. Explore some of our recent work. Get an insight on which solution will perfectly align with your brand's needs." />
            <meta property="og:image" content="/assets/moocourses_proj.png" />
            <meta property="og:url" content="https://raasissoftware.com/built-by-us" />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content="Built By Us | Ra'Asis" />
            <meta name="twitter:description" content="Our innovative creations empower startups & upcoming brands. Explore some of our recent work. Get an insight on which solution will perfectly align with your brand's needs." />
            <meta name="twitter:image" content="/assets/moocourses_proj.png" />
        </Helmet>
        <div className="recent-projects-section built-by-us">
            <div className="recent-projects-intro built-by-us-intro">
                <h3>Our Innovative Creations Empower Startups & Upcoming Brands</h3>
                <h2>Explore Some Of Our Recent Work</h2>
                <h3>Get An Insight On Which Solution Will Perfectly Align With Your Brand's Needs</h3>
            </div>
            <div className='rp-grid-area bbu-grid-area'>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/moocourses_proj.png' alt='Transfrontières MooCourses Project'/>
                        <img className='gif' src='/assets/moocourses_proj_long.gif' alt='Transfrontières MooCourses Project'/>
                    </div>
                    <div className='project-description'>
                        <p>An online learning platform with customised EdTech tools through which learners and tutors interact and share knowledge.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/moocourses' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/stormerz_proj.png' alt='MMU Softball Graphics Project'/>
                        <img className='gif' src='/assets/stormerz_proj_long.gif' alt='MMU Softball Graphics Project'/>
                    </div>
                    <div className='project-description'>
                        <p>Logo and home jersey designs for Multimedia University of Kenya, Softball & Baseball team to foster brand identity and recognition.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/stormerz' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/zeway_proj.png' alt='Zeway Builders Ecommerce Project'/>
                        <img className='gif' src='/assets/zeway_proj_long.gif' alt='Zeway Builders Ecommerce Project'/>
                    </div>
                    <div className='project-description'>
                        <p>An ecommerce web application where Zeway Builders clients can view, order and pay for building and construction materials and services.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/zeway-builders-ecommerce' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/transfro_proj.png' alt='Transfrontières Agencies Project'/>
                        <img className='gif' src='/assets/transfro_proj_long.gif' alt='Transfrontières Agencies Project'/>
                    </div>
                    <div className='project-description'>
                        <p>An integrated services website for Transfrontières Agencies Limited to organize their operations and connect with clients online.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/transfrontieres-agencies-website' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/oaksafrica_proj.png' alt='Oaks Africa Foundation Project'/>
                        <img className='gif' src='/assets/oaksafrica_proj_long.gif' alt='Oaks Africa Foundation Project'/>
                    </div>
                    <div className='project-description'>
                        <p>Oaks Africa Foundation's online space to maximise their efficiency in community development initiatives and programs.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/oaksafrica' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/infernos_proj.png' alt='Mombasa Infernos Softball & Baseball Project'/>
                        <img className='gif' src='/assets/infernos_proj_long.gif' alt='Mombasa Infernos Softball & Baseball Project'/>
                    </div>
                    <div className='project-description'>
                        <p>Captivating logo and jersey designs for Mombasa Infernos Softball and Baseball Club that have massively elevated their brand identity.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/mombasainfernos' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/mugeiyot_proj.png' alt='Mugeiyot Supermarkets Project'/>
                        <img className='gif' src='/assets/mugeiyot_proj_long.gif' alt='Mugeiyot Supermarkets Project'/>
                    </div>
                    <div className='project-description'>
                        <p>Engaging social media posts and graphical content for The Mugeiyot Supermarkets group to increase their online reach.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/mugeiyot' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
                <div className="recent-projects-grid built-projects-grid">
                    <div className='recent-project-item'>
                        <img src='/assets/taai_proj.png' alt='Taai Media Project'/>
                        <img className='gif' src='/assets/taai_proj_long.gif' alt='Taai Media Project'/>
                    </div>
                    <div className='project-description'>
                        <p>Graphic designs, software tools and continued partneship in events since 2022. We handle Software & IT for Taai Media.</p>
                        <div className='proj-desc-btns'>
                            <Link to='/case-study/taaimedia' onClick={handleLinkClick}>Case Study</Link>
                            <Link to='/contact' onClick={handleLinkClick}>Get Quote</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default BuiltByUs;

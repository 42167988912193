import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

const AboutIntroduction = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const rotatingTexts = [
    "We are dedicated to delivering innovative digital solutions that will significantly scale up your brand or business and streamline your operations.",
    "Our mission is to empower businesses with cutting-edge technology and reinforce their development teams with Software Engineering expertise.",
    "We believe in fostering long-term partnerships and relationships with our clients, a culture that brings about mutual digital success.",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % rotatingTexts.length);
    }, 10000);

    return () => clearInterval(intervalId);
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <section className="about-introduction">
      <div className="about-intro-left">
        <h3>About Ra'Asis</h3>
        <h1>We Strive To Create <span>Sustainable Long-Term Digital Solutions</span></h1>
        <p>{rotatingTexts[currentTextIndex]}</p>
        <div className='about-intro-btn'>
            <Link to="/contact" onClick={handleLinkClick}>Assemble Your Dev. Team</Link>
        </div>
      </div>
      <div className="about-intro-right">
        <div className='img-container about-intro-right-img'>
            <img src="/assets/analysis.webp" alt="A software technician conducting testing and analysis of software in a high tech. laboratory" />
        </div>
      </div>
    </section>
  );
};

export default AboutIntroduction;

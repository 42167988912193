import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Maintenance = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Maintenance Notice | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };
  
  return (
    <>
      <Helmet>
        <title>Maintenance Notice | Ra'Asis</title>
        <meta name="description" content="Our innovative creations empower startups & upcoming brands. Explore some of our recent work. Get an insight on which solution will perfectly align with your brand's needs." />
      </Helmet>
      <div className="maintenance-container">
        <div className='maintenance-content'>
          <div className="maintenance-icon">
            <i className="material-symbols-sharp">handyman</i>
          </div>
          <div className='maintenance-text'>
            <h1>Under Maintenance</h1>
            <p>This resource is undergoing scheduled maintenance. Come back in a few hours to access it. Meanwhile, explore other parts of our app..</p>
          </div>
          <div className='maintenance-btns'>
            <Link to="/" onClick={handleLinkClick}>Go Back Home</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Maintenance;
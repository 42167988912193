import React, {useState} from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const Footer = () => {

    const handleButtonClick = (event) => {

      const buttonText = event.target.textContent;

      ReactGA.event({
        category: 'User Interaction',
        action: 'Clicked A Button',
        label: buttonText,
      });
    };

    const handleLinkClick = (event) => {

      const linkText = event.target.textContent;
  
      ReactGA.event({
        category: 'User Interaction',
        action: 'Clicked A Btn Link',
        label: linkText,
      });
    };

    const [formData, setFormData] = useState({
        email_input: '',
      });
    
      const [error, setError] = useState({});
      const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message
    
      const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Validation
        const newErrors = {};
        if (!formData.email_input) newErrors.email_input = 'Please enter your e-mail address!';
    
        if (Object.keys(newErrors).length > 0) {
          setError(newErrors);
          return;
        }

        // Use EmailJS to send the email
        emailjs.sendForm('service_2e85syi', 'template_n2c9h5z', e.target, '9mxMmkBQDOb5dnt58')
        .then((result) => {
        console.log('Email sent successfully:', result.text);
        }, (error) => {
        console.error('Email sending failed:', error.text);
        });
    
        // Data Collection (Will Handle Here Next)
    
        // Show success message
        setShowSuccessMessage(true);
    
        // Clear form
        setFormData({
          email_input: '',
        });
        setError({});
    
        // Hide success message after 10 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 10000);
      };

  return (
    <footer>
        <div className="footer">
            <div className="footer-left">
                <div className="footer-logo">
                    <Link to="/">
                        <img src="/assets/raasis_logo.svg" alt="Ra'Asis Software logo" />
                    </Link>
                </div>
                <div className="footer-text">
                    <p>Explore and employ value-based business innovations for your business with our robust and secure digital ecosystems, designs, and sustainable digital solutions.</p>
                </div>
                <div className="footer-subscribe">
                    <form className="subscribe" onSubmit={handleSubmit}>
                        <label htmlFor="email_input">Subscribe For Weekly Tech Insights</label><br />
                        <input type="email" id="email_input" name='email_input' placeholder="Your E-mail Address" value={formData.email_input} onChange={handleChange} />
                        <button type="submit" id="subscribe" onClick={handleButtonClick}>Subscribe</button>
                        <br />
                        {error.email_input && <p className='error-message'>{error.email_input}</p>}
                        {showSuccessMessage && (
                            <div className="success-message footer-success">
                                Successfull, you will receive weekly updates...
                            </div>
                        )}
                        <small>*Your Personal Info Will Remain Confidential</small>
                    </form>
                </div>
            </div>
            <div className="footer-center">
                <div className="center-left">
                    <h4>Services</h4>
                    <ul>
                        <li><Link to="/services/mobile-application">Mobile Application Development</Link></li>
                        <li><Link to="/services/mvp-development">MVP Development</Link></li>
                        <li><Link to="/services/website-design">Website Design & Development</Link></li>
                        <li><Link to="/services/computer-architecture">Computer Architecture</Link></li>
                        <li><Link to="/services/software-project">Software Project Management</Link></li>
                        <li><Link to="/services/graphics-design">Graphics Design</Link></li>
                        <li><Link to="/services/web-application">Web Application Development</Link></li>
                        <li><Link to="/services/software-development">Software Development</Link></li>
                        <li><Link to="/services/maintenance">Maintenance & Management</Link></li>
                    </ul>
                </div>
                <div className="center-right">
                    <h4>About Us</h4>
                    <ul>
                        <li><Link to="/about-us">Our Culture</Link></li>
                        <li><Link to="/about-us/our-team">The Ra'Asis Team</Link></li>
                        <li><Link to="/built-by-us">Our Work</Link></li>
                        <li><Link to="/testimonials">Testimonials</Link></li>
                        <li><Link to="/about-us">Security At Ra'Asis</Link></li>
                        <li><Link to="/about-us">Robust & Secure</Link></li>
                        <li><Link to="/technologies">Technologies</Link></li>
                        <li><Link to="/about-us/blog">Blog</Link></li>
                        <li><Link to="/industries">Industries</Link></li>
                    </ul>
                </div>
            </div>
            <div className='footer-right'>
                <div className='connect'>
                    <h4>Let's Connect</h4>
                    <ul>
                        <li><Link to="mailto:info@raasissoftware.com" onClick={handleLinkClick}><i className='bx bx-mail-send'></i>info@raasissoftware.com</Link></li>
                        <li><Link to="tel:+254742807455" onClick={handleLinkClick}><i className='bx bx-phone-call'></i>+254742807455</Link></li>
                        <li><Link to="https://www.google.com/maps/place/Nairobi/@-1.3028603,36.6825778,11z/data=!3m1!4b1!4m6!3m5!1s0x182f1172d84d49a7:0xf7cf0254b297924c!8m2!3d-1.2920659!4d36.8219462!16zL20vMDVkNDk?entry=ttu" target='_blank' rel='noopener noreferrer' onClick={handleLinkClick}><i className='bx bx-current-location'></i>Nairobi, Kenya</Link></li>
                    </ul>
                </div>
                <div className="footer-socials">
                    <Link to="https://web.facebook.com/raasissoftware/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-facebook'></i></Link>
                    <Link to="https://api.whatsapp.com/send?phone=254742807455&text=Hello%20Ra'Asis%20Software%20I%20got%20your%20WhatsApp%20information%20from%20your%20website.%20I%20want%20to%20inquire%20about..." target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-whatsapp'></i></Link>
                    <Link to="https://www.instagram.com/ra_asis_software/" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}><i className='bx bxl-instagram'></i></Link>
                    <Link to="https://twitter.com/ra_asis_SW" target="_blank" rel="noopener noreferrer"><i className='bx bxl-twitter' onClick={handleLinkClick}></i></Link>
                </div>
            </div>
        </div>
        <div className='footer-lower'>
            <div className="copyright">
                <h6><i className='bx bx-copyright'></i>2023 || Powered By: Ra'Asis Software || All Rights Reserved</h6>
            </div>
            <div className='terms-policy'>
                <h6><Link to='/terms-conditions' onClick={handleLinkClick}>Terms & Conditions</Link> || <Link to='/privacy-policy' onClick={handleLinkClick}>Privacy Policy </Link> || Sitemap</h6>
            </div>
        </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const TermsConditions = () => {

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "Terms & Conditions | Ra'Asis",
        });
    });

    const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: 'User Interaction',
          action: 'Clicked A Btn Link',
          label: linkText,
        });
      };

  return (
    <>

        <Helmet>
            <title>Terms & Conditions | Ra'Asis</title>
            <meta name="description" content="Welcome to Ra'Asis Software! These terms and conditions outline the rules and regulations for the use of our website and services." />
        </Helmet>

        <div className='terms-conditions privacy-notice'>
            <h2>Terms & Conditions</h2>
            <p>
                Welcome to Ra'Asis Software! These terms and conditions outline the rules and regulations for the use of our website and services.
            </p>
            <h3>1. Acceptance Of Terms</h3>
            <p>
                By accessing this website or using our services, you agree to be bound by these terms and conditions. If you disagree with any part of these terms and conditions, you may not access the website or use our services.
            </p>
            <h3>2. Use Of Website</h3>
            <p>
                You agree to use this website only for lawful purposes and in a manner consistent with all applicable laws and regulations. You further agree not to use the website in any way that may cause damage to the website or impair the availability or accessibility of the website.
            </p>
            <h3>3. Intellectual Property</h3>
            <p>
                All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Ra'Asis Software and is protected by intellectual property laws.
            </p>
            <h3>4. Limitation Of Liability</h3>
            <p>
                In no event shall Ra'Asis Software, nor any of its officers, directors, employees, or agents, be liable to you for anything arising out of or in any way connected with your use of this website or our services, whether such liability is under contract, tort, or otherwise.
            </p>
            <h3>5. Governing Law</h3>
            <p>
                These terms and conditions shall be governed by and construed in accordance with the laws of The Republic Of Kenya, and any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of The Republic Of Kenya.
            </p>
            <h3>6. Changes To Terms & Conditions</h3>
            <p>
                We reserve the right, at our discretion, to modify or replace these terms and conditions at any time. It is your responsibility to check these terms and conditions periodically for changes. Your continued use of the website or our services after the posting of any changes to these terms and conditions constitutes acceptance of those changes.
            </p>
            <h3>Contact Us</h3>
            <p>
                If you have any questions or concerns about our terms and conditions, please contact us at <Link to='mailto:terms@raasissoftware.com' onClick={handleLinkClick}>terms@raasissoftware.com.</Link>
            </p>
        </div>
    </>
  );
}

export default TermsConditions;

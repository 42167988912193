import React, { useState} from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const LeadGenerationSection = () => {

  const handleButtonClick = (event) => {

    const buttonText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Button',
      label: buttonText,
    });
  };

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    serviceInterest: '',
    budgetInterest: '',
    timeline: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for success message

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Please enter your name!';
    if (!formData.phone) newErrors.phone = 'Please enter your phone number!';
    if (!formData.email) newErrors.email = 'Please enter your e-mail address!';
    if (!formData.serviceInterest) newErrors.serviceInterest = 'Please select the service you require!';
    if (!formData.budgetInterest) newErrors.budgetInterest = 'Please select your estimated budget!';
    if (!formData.timeline) newErrors.timeline = 'Please select your estimated timeline!';
    if (!formData.message) newErrors.message = 'Please describe your project in a few words!';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Use EmailJS to send the email
    emailjs.sendForm('service_2e85syi', 'template_n2c9h5z', e.target, '9mxMmkBQDOb5dnt58')
    .then((result) => {
      console.log('Email sent successfully:', result.text);
    }, (error) => {
      console.error('Email sending failed:', error.text);
    });

    // Data Collection (Will Handle Here Next)

    // Show success message
    setShowSuccessMessage(true);


    // Clear form
    setFormData({
      name: '',
      phone: '',
      email: '',
      serviceInterest: '',
      budgetInterest: '',
      timeline: '',
      message: ''
    });
    setErrors({});

    // Hide success message after 10 seconds
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 10000);
  };


  return (
    <div className="lead-generation-section">
      <div className="lead-gen-intro">
        <h2>We Are Software Engineers, Combining Code & Creativity With Agile Software Engineering Processes</h2>
        <p>
          Check out the case studies for what we have built recently over at the <Link to="/built-by-us"> Built By Us </Link>
          page. If you are ready to work, let us discuss your project in detail.<br />
        </p>
      </div>
      <div className="form-area">
          <form id="contact-us" onSubmit={handleSubmit}>
              <div className="top-row">
                  <div className="inside">
                      <label htmlFor="name" id="namelab">Name<span>*</span></label><br /><br />
                      <input type="text" id="name" name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <p className='error-message'>{errors.name}</p>}
                  </div>
                  <div className="inside" id="phonelab">
                      <label htmlFor="phone">Phone Number<span>*</span></label><br /><br />
                      <input type="tel" id="phone" name='phone' value={formData.phone} onChange={handleChange} />
                      {errors.phone && <p className='error-message'>{errors.phone}</p>}
                  </div>
                  <div className="inside" id="emaillab">
                      <label htmlFor="email">E-Mail Address<span>*</span></label><br /><br />
                      <input type="email" id="email" name='email' value={formData.email} onChange={handleChange} />
                      {errors.email && <p className='error-message'>{errors.email}</p>}
                  </div>
              </div><br /><br /><br />
              <div className="middle-row">
                  <div className="inside" id='select_service'>
                    <label htmlFor="serviceInterest">Service Of Interest<span>*</span></label><br /><br />
                    <select id="serviceInterest" name='serviceInterest' value={formData.serviceInterest} onChange={handleChange}>
                        <option value="">Select Service</option>
                        <option value="Software Development">Software Development</option>
                        <option value="Website Design & Development">Website Design & Development</option>
                        <option value="Web Application Development">Web Application Development</option>
                        <option value="Mobile Application Development">Mobile Application Development</option>
                        <option value="MVP Development">MVP Development</option>
                        <option value="Maintenance">Maintenance</option>
                        <option value="Software Project Management">Software Project Management</option>
                        <option value="Computer Architecture">Computer Architecture</option>
                        <option value="Graphics Design">Graphics Design</option>
                    </select>
                    {errors.serviceInterest && <p className='error-message'>{errors.serviceInterest}</p>}
                </div>
                <div className='inside' id='select_budget'>
                  <label htmlFor="budgetInterest">Your Budget Range<span>*</span></label><br /><br />
                  <select id="budgetInterest" name='budgetInterest' value={formData.budgetInterest} onChange={handleChange}>
                      <option value="">Choose Budget</option>
                      <option value="Below USD 100">Below USD 100</option>
                      <option value="USD 100 - USD 200">USD 100 - USD 200</option>
                      <option value="USD 200 - USD 400">USD 200 - USD 400</option>
                      <option value="USD 400 - USD 800">USD 400 - USD 800</option>
                      <option value="USD 800 - USD 1600">USD 800 - USD 1600</option>
                      <option value="USD 1600 - USD 3200">USD 1600 - USD 3200</option>
                      <option value="Above USD 3200">Above USD 3200</option>
                      {/* Will add more options as needed */}
                  </select>
                  {errors.budgetInterest && <p className='error-message'>{errors.budgetInterest}</p>}
                </div>
                <div className='inside' id='select_budget'>
                  <label htmlFor="timeline">Project Timeline<span>*</span></label><br /><br />
                  <select id="timeline" name='timeline' value={formData.timeline} onChange={handleChange}>
                      <option value="">Select Timeline</option>
                      <option value="3 - 5 Days">3 - 5 Days</option>
                      <option value="1 - 2 Weeks">1 - 2 Weeks</option>
                      <option value="2 - 3 Weeks">2 - 3 Weeks</option>
                      <option value="3 - 4 Weeks">3 - 4 Weeks</option>
                      <option value="4 - 6 Weeks">4 - 6 Weeks</option>
                      <option value="6 - 8 Weeks">6 - 8 Weeks</option>
                      <option value="Beyond 8 Weeks">Beyond 8 Weeks</option>
                      {/* Will add more options as needed */}
                  </select>
                  {errors.timeline && <p className='error-message'>{errors.timeline}</p>}
                </div>
              </div>
              <br /><br /><br />
              <div className='bottom-row'>
                <div className="inside" id='project_desc'>
                  <label htmlFor="message">Project Description<span>*</span></label><br /><br />
                  <textarea id="message" name='message' cols="32" rows="10" value={formData.message} onChange={handleChange}></textarea><br />
                  {errors.message && <p className='error-message'>{errors.message}</p>}
                </div>
              </div>
              <div className="submit-btn">
                  <button type="submit" id="submit_btn" onClick={handleButtonClick}>Send Your Details</button>
              </div>
          </form>
          {showSuccessMessage && (
              <div className="success-message">
                Your details have been mailed successfully, we will get back to you shortly...
              </div>
            )}
        </div>
    </div>
  );
};

export default LeadGenerationSection;

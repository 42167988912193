import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'

const Industries = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Industries | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>Industries | Ra'Asis</title>
        <meta name="description" content="We aim to imprint our digital footprints all over the vast innovative space available for all essential industries in Kenya, Africa and The World." />
        <meta property="og:title" content="Industries | Ra'Asis" />
        <meta property="og:description" content="We aim to imprint our digital footprints all over the vast innovative space available for all essential industries in Kenya, Africa and The World." />
        <meta property="og:image" content="/assets/sports.png" />
        <meta property="og:url" content="https://raasissoftware.com/industries" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Industries | Ra'Asis" />
        <meta name="twitter:description" content="We aim to imprint our digital footprints all over the vast innovative space available for all essential industries in Kenya, Africa and The World." />
        <meta name="twitter:image" content="/assets/sports.png" />
        <meta name="twitter:card" content="/assets/media.png" />
      </Helmet>
      <div className="introduction-container industries-intro">
            <div className="left-section">
              <div className="img-container">
                <img src='/assets/industries.webp' alt='industries' />
              </div>
            </div>
            
            <div className="right-section">
                <div className='intro-headings'>
                  <h4><Link to='/'><span>Home</span></Link> / Industries</h4>
                  <h3>Embrace The Universal Power Of Innovation</h3>
                  <h2>Our Digital Solutions Are Applicable Across Various Industries</h2>
                </div>
                <div className="intro-paragraph">
                  <p>
                    We aim to imprint our digital footprints all over the vast innovative space available for all essential industries in Kenya, Africa and The World. Are you an individual, a startup or even an established market leader in your industry ? We can deliver the perfect digital product to give you a sharper competitive edge.
                  </p>
                </div>

                {/* Buttons Section */}
                <div className="intro-buttons">
                  <Link to="/contact" id="dev_team" onClick={handleLinkClick}>
                    Get Your Dev Team
                  </Link>
                  <Link to="/contact" id="more_about" onClick={handleLinkClick}>
                    Get Quick Quote
                  </Link>
                </div>
            </div>
        </div>

        {/* Education */}

        <section className="about-introduction industry-container edu-industry">
          <div className="about-intro-left edu-industry-left">
            <h2>Education</h2>
            <p>
              In the wake of the global pandemic, the education sector underwent a remarkable transformation, embracing modern technology to adapt and thrive in a rapidly changing landscape. This shift catalyzed the emergence of the Education Technology (EdTech) industry, which is projected to surpass $3 billion by 2027. The digitalization of education brought forth unprecedented transparency and introduced a wide variety of essential tools to facilitate teaching and learning, including homework monitoring, virtual classes, submission tracking, and smart teaching methodologies. Recognizing the immense potential of these technologies, we have redirected our focus towards the EdTech industry. Our mission is to craft intuitive, student-centric EdTech applications and websites that redefine the learning experience and empower educators and learners alike.
            </p>
            <ul className='industry-unordered'>
              <li>Education Portals</li>
              <li>Student Progress Analytics</li>
              <li>Learning Management Systems</li>
              <li>Custom Quizzes & Tests</li>
            </ul>
            <div className='about-intro-btn edu-industry-btn'>
                <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
            </div>
          </div>
          <div className="about-intro-right edu-industry-right">
            <div className='img-container about-intro-right-img edu-industry-right-img'>
                <img src="/assets/education.png" alt="education" />
                <span class="circle-spin"></span>
            </div>
          </div>
      </section>

      {/* Sports Mobile */}

      <section className="about-introduction industry-container m-sports-industry industry-for-below">
          <div className="about-intro-left m-sports-industry-left">
            <h2>Sports & Gaming</h2>
            <p>
              The Sports Industry stands at the forefront of innovation, constantly evolving to meet the demands of an increasingly tech-savvy audience. In recent years, technology has revolutionized the way sports are played, watched, and managed. From advanced analytics and performance tracking to immersive fan experiences and virtual competitions, the integration of technology has reshaped every aspect of the sporting landscape. At our core, we embrace this digital revolution and collaborate with stakeholders in the Sports Industry to harness the power of technology. Whether it's developing state-of-the-art sports management platforms, designing team brand assets and full uniforms, or enhancing fan engagement through interactive mobile applications, we're committed to driving innovation and pushing the boundaries of what is possible in sports technology.
            </p>
            <ul className='industry-unordered'>
              <li>Team Full Kit Designs</li>
              <li>Fitness Analytics Apps</li>
              <li>Virtual Gaming Platforms</li>
              <li>Score Tracking Apps</li>
            </ul>
            <div className='about-intro-btn m-sports-industry-btn'>
                <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
            </div>
          </div>
          <div className="about-intro-right m-sports-industry-right">
            <div className='img-container about-intro-right-img m-sports-industry-right-img'>
                <img src="/assets/sports.png" alt="sports" />
                <span class="circle-spin"></span>
            </div>
          </div>
      </section>

      {/* Sports */}

      <div className="introduction-container industry-container sports-industry industry-for-above">
            <div className="left-section sports-industry-left">
              <div className="img-container">
                <img src='/assets/sports.png' alt='sports' />
                <span class="circle-spin"></span>
              </div>
            </div>
            
            <div className="right-section sports-industry-right">
                <div className='intro-headings'>
                  <h2>Sports & Gaming</h2>
                </div>
                <div className="intro-paragraph">
                  <p>
                    The Sports Industry stands at the forefront of innovation, constantly evolving to meet the demands of an increasingly tech-savvy audience. In recent years, technology has revolutionized the way sports are played, watched, and managed. From advanced analytics and performance tracking to immersive fan experiences and virtual competitions, the integration of technology has reshaped every aspect of the sporting landscape. At our core, we embrace this digital revolution and collaborate with stakeholders in the Sports Industry to harness the power of technology. Whether it's developing state-of-the-art sports management platforms, designing team brand assets and full uniforms, or enhancing fan engagement through interactive mobile applications, we're committed to driving innovation and pushing the boundaries of what is possible in sports technology.
                  </p>
                  <ul className='industry-unordered'>
                    <li>Team Full Kit Designs</li>
                    <li>Fitness Analytics Apps</li>
                    <li>Virtual Gaming Platforms</li>
                    <li>Score Tracking Apps</li>
                  </ul>
                  <div className='about-intro-btn sports-industry-btn'>
                      <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
                  </div>
                </div>
            </div>
        </div>

        {/* Finance */}

        <section className="about-introduction industry-container finance-industry">
          <div className="about-intro-left finance-industry-left">
            <h2>Finance & Banking</h2>
            <p>
               As digital banking and FinTech solutions continue to gain traction, traditional financial institutions are embracing innovation to stay competitive in an ever expanding digital landscape. From mobile banking apps and contactless payments to blockchain technology and robo-advisors, the industry is witnessing a rapid evolution towards more efficient, secure, and customer-centric financial services. At Ra'Asis, we recognize the immense opportunities presented by this digital revolution and are committed to helping banks, financial institutions, and FinTech startups navigate their complex niche. We develop highly robust and secure banking platforms, implementing AI-driven risk management solutions and enhancing efficient cybersecurity protocols.
            </p>
            <ul className='industry-unordered'>
              <li>Neo Banking Platforms</li>
              <li>Online Payment Systems</li>
              <li>Investment Software</li>
              <li>Trading Platforms</li>
            </ul>
            <div className='about-intro-btn finance-industry-btn'>
                <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
            </div>
          </div>
          <div className="about-intro-right finance-industry-right">
            <div className='img-container about-intro-right-img finance-industry-right-img'>
                <img src="/assets/finance.png" alt="finance and banking" />
                <span className="circle-spin"></span>
            </div>
          </div>
      </section>

      {/* eCommerce Mobile */}

      <section className="about-introduction industry-container m-ecommerce-industry industry-for-below">
          <div className="about-intro-left m-ecommerce-industry-left">
            <h2>eCommerce</h2>
            <p>
              Shifts in consumer behavior and advancements in digital technology have made eCommerce what it is today. With the convenience of online shopping becoming increasingly popular, businesses are continually seeking innovative ways to enhance their eCommerce platforms and streamline the customer experience. We have specialized in delivering comprehensive eCommerce solutions tailored to the unique needs of businesses across various sectors. We develop user-friendly web and mobile apps and implement advanced analytics and personalized marketing strategies to empower eCommerce businesses to drive sales, increase customer engagement, and stay ahead of the competition. We have a team of experienced developers and digital marketers who collaborate closely with clients to design, develop and deploy modern eCommerce solutions that deliver measurable results and foster long-term growth.
            </p>
            <ul className='industry-unordered'>
              <li>eCommerce Portals</li>
              <li>Logistics & Warehousing</li>
              <li>Inventory Management Systems</li>
              <li>BigCommerce</li>
            </ul>
            <div className='about-intro-btn m-ecommerce-industry-btn'>
                <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
            </div>
          </div>
          <div className="about-intro-right m-ecommerce-industry-right">
            <div className='img-container about-intro-right-img m-ecommerce-industry-right-img'>
                <img src="/assets/ecommerce.png" alt="e-commerce" />
                <span class="circle-spin"></span>
            </div>
          </div>
      </section>

      {/* eCommerce */}

      <div className="introduction-container industry-container ecommerce-industry industry-for-above">
            <div className="left-section ecommerce-industry-left">
              <div className="img-container">
                <img src='/assets/ecommerce.png' alt='e-commerce' />
                <span class="circle-spin"></span>
              </div>
            </div>
            
            <div className="right-section ecommerce-industry-right">
                <div className='intro-headings'>
                  <h2>eCommerce</h2>
                </div>
                <div className="intro-paragraph">
                  <p>
                    Shifts in consumer behavior and advancements in digital technology have made eCommerce what it is today. With the convenience of online shopping becoming increasingly popular, businesses are continually seeking innovative ways to enhance their eCommerce platforms and streamline the customer experience. We have specialized in delivering comprehensive eCommerce solutions tailored to the unique needs of businesses across various sectors. We develop user-friendly web and mobile apps and implement advanced analytics and personalized marketing strategies to empower eCommerce businesses to drive sales, increase customer engagement, and stay ahead of the competition. We have a team of experienced developers and digital marketers who collaborate closely with clients to design, develop and deploy modern eCommerce solutions that deliver measurable results and foster long-term growth.
                  </p>
                  <ul className='industry-unordered'>
                    <li>eCommerce Portals</li>
                    <li>Logistics & Warehousing</li>
                    <li>Inventory Management Systems</li>
                    <li>BigCommerce</li>
                  </ul>
                  <div className='about-intro-btn ecommerce-industry-btn'>
                      <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
                  </div>
                </div>
            </div>
        </div>

        {/* Media & Entertainment */}

        <section className="about-introduction industry-container media-industry">
          <div className="about-intro-left media-industry-left">
            <h2>Media & Entertainment</h2>
            <p>
              In the Media and Entertainment industry, content consumption habits are evolving rapidly. We are now deep inside the age of streaming services, social media, and personalized experiences. As consumers demand more immersive and interactive entertainment options, businesses in this sector are embracing digital innovation to captivate audiences and drive engagement. Our company is at the forefront of this transformation, offering a wide range of solutions to help media and entertainment companies thrive. From developing interactive content platforms and mobile applications to implementing powerful technologies such as augmented reality (AR) and virtual reality (VR), we enable our clients to deliver compelling content experiences that resonate with their target audiences. Whether it's enhancing content delivery, monetizing digital assets, or leveraging data analytics for audience insights, we provide tailored solutions that empower media and entertainment businesses to succeed in a rapidly evolving digital ecosystem.
            </p>
            <ul className='industry-unordered'>
              <li>News Streaming Apps</li>
              <li>Social Media Platforms</li>
              <li>Ticket Booking Systems</li>
              <li>Photo Editing & Sharing</li>
            </ul>
            <div className='about-intro-btn media-industry-btn'>
                <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
            </div>
          </div>
          <div className="about-intro-right media-industry-right">
            <div className='img-container about-intro-right-img media-industry-right-img'>
                <img src="/assets/media.png" alt="media and entertainment" />
                <span class="circle-spin"></span>
            </div>
          </div>
      </section>

      {/* Building & Construction Mobile */}

      <section className="about-introduction industry-container m-build-industry industry-for-below">
          <div className="about-intro-left m-build-industry-left">
            <h2>Building & Construction</h2>
            <p>
              The Building and Construction industry plays a pivotal role in shaping the physical environment we live in, encompassing everything from residential and commercial developments to infrastructure projects and urban planning initiatives. With the increasing emphasis on sustainability, efficiency, and innovation, there is a growing demand for technological solutions that can streamline construction processes, improve project management, and enhance safety and sustainability standards. At Ra'Asis, we leverage the latest advancements in technology, such as Building Information Modeling (BIM), drone mapping, and construction management software, to provide comprehensive solutions tailored to the needs of the building and construction sector. Through our digital design and visualization tools as well as project monitoring and asset management platforms, we empower construction firms, architects, and engineers to optimize their workflows, reduce costs, and deliver projects of the highest quality.  Combining industry expertise with technological innovation drives positive change and helps to shape the future of building and construction.
            </p>
            <ul className='industry-unordered'>
              <li>Building 3D Modelling</li>
              <li>Construction Management</li>
              <li>Visualization Tools</li>
              <li>Workflow Optimization Apps</li>
            </ul>
            <div className='about-intro-btn m-build-industry-btn'>
                <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
            </div>
          </div>
          <div className="about-intro-right m-build-industry-right">
            <div className='img-container about-intro-right-img m-build-industry-right-img'>
                <img src="/assets/building.png" alt="building and construction" />
                <span class="circle-spin"></span>
            </div>
          </div>
      </section>

      {/* Building & Construction*/}

      <div className="introduction-container industry-container build-industry industry-for-above">
            <div className="left-section build-industry-left">
              <div className="img-container">
                <img src='/assets/building.png' alt='building and construction' />
                <span class="circle-spin"></span>
              </div>
            </div>
            
            <div className="right-section build-industry-right">
                <div className='intro-headings'>
                  <h2>Building & Construction</h2>
                </div>
                <div className="intro-paragraph">
                  <p>
                    The Building and Construction industry plays a pivotal role in shaping the physical environment we live in, encompassing everything from residential and commercial developments to infrastructure projects and urban planning initiatives. With the increasing emphasis on sustainability, efficiency, and innovation, there is a growing demand for technological solutions that can streamline construction processes, improve project management, and enhance safety and sustainability standards. At Ra'Asis, we leverage the latest advancements in technology, such as Building Information Modeling (BIM), drone mapping, and construction management software, to provide comprehensive solutions tailored to the needs of the building and construction sector. Through our digital design and visualization tools as well as project monitoring and asset management platforms, we empower construction firms, architects, and engineers to optimize their workflows, reduce costs, and deliver projects of the highest quality.  Combining industry expertise with technological innovation drives positive change and helps to shape the future of building and construction.
                  </p>
                  <ul className='industry-unordered'>
                    <li>Building 3D Modelling</li>
                    <li>Construction Management</li>
                    <li>Visualization Tools</li>
                    <li>Workflow Optimization Apps</li>
                  </ul>
                  <div className='about-intro-btn build-industry-btn'>
                      <Link to="/contact" onClick={handleLinkClick}>Get More Information</Link>
                  </div>
                </div>
            </div>
        </div>
        <section className='industries-lead-gen'>
        <div className='industries-lead-gen-main'>
          <div className='industries-lead-gen-heading'>
            <h3>Let's Build Something Amazing In Your Industry</h3>
          </div>
          <div className='industries-lead-gen-icon'>
            <i className='bx bx-horizontal-right' id='right'></i>
            <i className='bx bx-download' id='down'></i>
          </div>
          <div className='industries-lead-gen-btn'>
            <Link to='/contact' onClick={handleLinkClick}>Start Project</Link>
          </div>
        </div>
      </section>
    </>
        
    );
}

export default Industries;
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Blog = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Blog | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>Blog | Ra'Asis</title>
        <meta name="description" content="Explore and employ value-based technological insights for your business or personal brand. Subscribe to get weekly updates on the latest topics" />
      </Helmet>
      <div className="maintenance-container">
        <div className='maintenance-content'>
          <div className="maintenance-icon">
            <i className="material-symbols-sharp">handyman</i>
          </div>
          <div className='maintenance-text'>
            <h1>Under Maintenance</h1>
            <p>Our blog is undergoing scheduled maintenance to give you a more insightful experience. Come back in a few hours...</p>
          </div>
          <div className='maintenance-btns'>
            <Link to="/" onClick={handleLinkClick}>Go To Homepage</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
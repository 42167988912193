import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import ReactGA from 'react-ga4';
import Header from './components/Header';
import IntroductionSection from './components/IntroductionSection';
import ProjectProcessSection from './components/ProjectProcessSection';
import TechnologiesSection from './components/TechnologiesSection';
import RecentProjectsSection from './components/RecentProjectsSection';
import ClientRecognitionSection from './components/ClientRecognitionSection';
import ClientTestimonialsSection from './components/ClientTestimonialsSection';
import WhyChooseSection from './components/WhyChooseSection';
import Footer from './components/Footer';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Industries from './components/Industries';
import BuiltByUs from './components/BuiltByUs';
import CaseStudy from './components/CaseStudy';
import HireADeveloper from './components/HireADeveloper';
import SoftwareDevelopment from './components/services/SoftwareDevelopment';
import WebsiteDesign from './components/services/WebsiteDesign';
import WebApplication from './components/services/WebApplication';
import MobileApplication from './components/services/MobileApplication';
import WebsiteMaintenance from './components/services/WebsiteMaintenance';
import SoftwareProject from './components/services/SoftwareProject';
import MvpDevelopment from './components/services/MvpDevelopment';
import ComputerArchitecture from './components/services/ComputerArchitecture';
import GraphicsDesign from './components/services/GraphicsDesign';
import OurCulture from './components/about-us/OurCulture';
import OurTeam from './components/about-us/OurTeam';
import Blog from './components/about-us/Blog';
import FrontEnd from './components/hire-a-developer/FrontEnd';
import BackEnd from './components/hire-a-developer/BackEnd';
import './App.css';
import Maintenance from './components/Maintenance';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';

ReactGA.initialize('G-BV22VJN7RG');

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 3500) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Helmet>
          <title>Ra'Asis | Software Development Company</title>
          <meta name="description" content="We design, build, deploy and manage custom software as well as digital products and tools that are utilised in a number of industries." />
          <meta property="og:title" content="Ra'Asis | Software Development Company" />
          <meta property="og:description" content="We design, build, deploy and manage custom software as well as digital products and tools that are utilised in a number of industries." />
          <meta property="og:image" content="/assets/robust_secure.png" />
          <meta property="og:url" content="https://raasissoftware.com" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Ra'Asis | Software Development Company" />
          <meta name="twitter:description" content="We design, build, deploy and manage custom software as well as digital products and tools that are utilised in a number of industries." />
          <meta name="twitter:image" content="/assets/robust_secure.png" />
          <meta name="twitter:card" content="/assets/quality_assured.png" />
        </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services/*" element={<ServicesRoutes />} />
          <Route path="/about-us/*" element={<AboutUsRoutes />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/built-by-us" element={<BuiltByUs />} />
          <Route path="/case-study/:id" element={<CaseStudy />} />
          <Route path="/hire-a-developer/*" element={<HireADeveloperRoutes />} />
          <Route path="/testimonials" element={<ClientTestimonialsSection />} />
          <Route path="/technologies" element={<TechnologiesSection />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
        <Footer />
        {showScrollToTop && (
          <div className='scroll-to-top'>
            <button onClick={scrollToTop}>
            <i className="bx bxs-up-arrow-circle"></i>
              Go Top
            </button>
          </div>
        )}
      </div>
    </Router>
  );
}

function Home() {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home | Ra'Asis",
    });
  });

  return (
    <>
      <IntroductionSection />
      <ProjectProcessSection />
      <TechnologiesSection />
      <RecentProjectsSection />
      <ClientRecognitionSection />
      <ClientTestimonialsSection />
      <WhyChooseSection />
    </>
  );
}

function ServicesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Services />} />
      <Route path="/software-development" element={<SoftwareDevelopment />} />
      <Route path="/website-design" element={<WebsiteDesign />} />
      <Route path="/web-application" element={<WebApplication />} />
      <Route path="/mobile-application" element={<MobileApplication />} />
      <Route path="/maintenance" element={<WebsiteMaintenance />} />
      <Route path="/software-project" element={<SoftwareProject />} />
      <Route path="/mvp-development" element={<MvpDevelopment />} />
      <Route path="/computer-architecture" element={<ComputerArchitecture />} />
      <Route path="/graphics-design" element={<GraphicsDesign />} />
    </Routes>
  );
}

function AboutUsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AboutUs />} />
      <Route path="/our-culture" element={<OurCulture />} />
      <Route path="/our-team" element={<OurTeam />} />
      <Route path="/blog" element={<Blog />} />
    </Routes>
  );
}

function HireADeveloperRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HireADeveloper />} />
      <Route path="/front-end" element={<FrontEnd />} />
      <Route path="/back-end" element={<BackEnd />} />
    </Routes>
  );
}

export default App;


/**RELEASE#4 (Version 0.1.5)**/
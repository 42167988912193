import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import WhyChooseSection from '../WhyChooseSection';

function FrontEnd() {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Hire A Front-End Dev. | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>Hire A Front-End Dev. | Ra'Asis</title>
        <meta name="description" content="Front-End development is where first impressions are made. Hiring a Front-End developer from Ra'Asis means you don't just get someone who is skilled in coding but also an artist who crafts visually stunning and user-friendly interfaces." />
        <meta property="og:title" content="Hire A Front-End Developer | Ra'Asis" />
        <meta property="og:description" content="Front-End development is where first impressions are made. Hiring a Front-End developer from Ra'Asis means you don't just get someone who is skilled in coding but also an artist who crafts visually stunning and user-friendly interfaces." />
        <meta property="og:url" content="https://raasissoftware.com/hire-a-developer/front-end" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Hire A Front-End Developer | Ra'Asis" />
        <meta name="twitter:description" content="Front-End development is where first impressions are made. Hiring a Front-End developer from Ra'Asis means you don't just get someone who is skilled in coding but also an artist who crafts visually stunning and user-friendly interfaces." />
        <meta name="twitter:image" content="/assets/raasis_logo.svg" />
      </Helmet>
      <section className="about-introduction hire-dev-intro">
      <div className="about-intro-left hire-dev-left">
        <h4><Link to='/'><span>Home</span></Link> / <Link to='/hire-a-developer'>Hire A Developer</Link> / Front-End</h4>
        <div className='hire-dev-headings'>
          <h3>A Front-End Developer For Your Project</h3>
          <h1>Enjoy A Beautiful & Interactive Feature Rich Application Made By One Of Our Own</h1>
        </div>
        <div className='hire-dev-text'>
          <p>Front-End development is where first impressions are made. Hiring a Front-End developer from Ra'Asis means you don't just get someone who is skilled in coding but also an artist who crafts visually stunning and user-friendly interfaces. Our developers pay acute attention to detail and have passion for innovation, they breathe life into designs and ensure seamless user experiences. Trust us to transform your vision into an engaging digital reality.</p>
        </div>
      </div>
      <div className="about-intro-right hire-dev-right">
        <div className='hire-dev-box'>
          <div className='hire-dev-box-cont'>
            <div className='stack-icon'>
              <i className='material-symbols-sharp'>flip_to_front</i>
            </div>
            <h3>A Single<br />Front-End<br />Developer</h3>
            <h3>Rate Is<br /><br /><span id='larger'>$10/</span><span id='smaller'>Hr</span></h3>
          </div>
          <div className='hire-dev-btn'>
              <Link to="/contact" onClick={handleLinkClick}>Get Front-End Dev.</Link>
          </div>
        </div>
        <div className='not-decided'>
          <p>Haven't made up your mind yet on what your project requires ?</p>
          <Link to='/contact' onClick={handleLinkClick}>Click here to book a free consultation</Link>
        </div>
      </div>
    </section>

    <WhyChooseSection />
  </>
  );
}

export default FrontEnd;
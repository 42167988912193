import React from 'react';

const RecentProjectsSection = () => {
  return (
    <div className="recent-projects-section">
      <div className="recent-projects-intro">
        <h2>Here's What We've Built & Deployed Recently</h2>
        <p>We empower startups and upcoming brands, with cutting-edge digital software products, that propel them to guaranteed success in their niche.</p>
      </div>
      <div className='rp-grid-area'>
        <div className="recent-projects-grid">
            <div className='recent-project-item'>
                <img src='/assets/moocourses_proj.png' alt='Transfrontières MooCourses Project'/>
                <img className='gif' src='/assets/moocourses_proj_long.gif' alt='Transfrontières MooCourses Project'/>
            </div>
        </div>
        <div className="recent-projects-grid">
            <div className='recent-project-item'>
                <img src='/assets/stormerz_proj.png' alt='MMU Softball Graphics Project'/>
                <img className='gif' src='/assets/stormerz_proj_long.gif' alt='MMU Softball Graphics Project'/>
            </div>
        </div>
        <div className="recent-projects-grid">
            <div className='recent-project-item'>
                <img src='/assets/zeway_proj.png' alt='Zeway Builders Ecommerce Project'/>
                <img className='gif' src='/assets/zeway_proj_long.gif' alt='Zeway Builders Ecommerce Project'/>
            </div>
        </div>
        <div className="recent-projects-grid">
            <div className='recent-project-item'>
                <img src='/assets/transfro_proj.png' alt='Transfrontières Agencies Project'/>
                <img className='gif' src='/assets/transfro_proj_long.gif' alt='Transfrontières Agencies Project'/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default RecentProjectsSection;

import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';

const MobileApplication = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Mobile Apps | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  const categories = [
    { id: 'mobile', name: 'Mobile', images: ['ios_swift.svg', 'kotlin_logo.svg', 'flutter.svg', 'react_native.svg', 'pwa_logo.svg'], labels: ['Swift', 'Kotlin', 'Flutter', 'React Native', 'PWA'] },
    { id: 'frontend', name: 'Front-End', images: ['html.svg', 'css.svg', 'javascript.svg', 'react.svg', 'angular.svg', 'vue.svg', 'next.svg', 'meteor.svg', 'gatsby.svg', 'nuxtjs_icon.svg'], labels: ['HTML', 'CSS', 'JavaScript', 'React', 'Angular', 'Vue', 'Next.js', 'Meteor', 'Gatsby', 'Nuxt.js'] },
    { id: 'backend', name: 'Back-End', images: ['java.svg', 'nest.svg', 'net_core.svg', 'net_mvc.svg', 'node.svg', 'php.svg', 'python.svg'], labels: ['Java', 'Nest', '.NET Core', '.NET MVC', 'Node.js', 'PHP', 'Python'] },
    { id: 'cms', name: 'CMS & ECommerce', images: ['contentful.svg', 'magento.svg', 'meteor.svg', 'quintype.svg', 'shopify.svg', 'strapi.svg', 'woo_commerce.svg', 'wordpress.svg'], labels: ['Contentful', 'Magento', 'Meteor', 'Quintype', 'Shopify', 'Strapi', 'WooCommerce', 'WordPress'] },
    { id: 'database', name: 'Database', images: ['firebase.svg', 'mongodb.svg', 'mysql_server.svg', 'NoSQL.svg', 'oracle.svg', 'postgre_SQl.svg', 'SQL_server.svg'], labels: ['Firebase', 'MongoDB', 'MySQL Server', 'NoSQL', 'Oracle', 'PostgreSQL', 'SQL Server'] },
    { id: 'cloud', name: 'Cloud', images: ['aws.svg', 'azure_logo.svg', 'gcp.svg', 'heroku.svg'], labels: ['AWS', 'Microsoft Azure', 'Google Cloud Platform', 'Heroku'] },
    { id: 'graphics', name: 'Graphics Design', images: ['adobe_illustrator.svg', 'adobe_indesign.svg', 'adobe_photoshop.svg', 'blender.svg', 'canva.svg', 'figma.svg'], labels: ['Adobe Illustrator', 'Adobe InDesign', 'Adobe Photoshop', 'Blender', 'Canva', 'Figma'] },
  ];

  //Manage Selected Category
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  return (
    <>
      <Helmet>
        <title>Mobile Apps | Ra'Asis</title>
        <meta name="description" content="Explore new avenues for your business or personal brand, partner with us in creating a robust, dynamic and secure mobile application." />
        <meta property="og:title" content="Mobile Apps | Ra'Asis" />
        <meta property="og:description" content="Explore new avenues for your business or personal brand, partner with us in creating a robust, dynamic and secure mobile application." />
        <meta property="og:image" content="/assets/raasis_logo.png" />
        <meta property="og:url" content="https://raasissoftware.com/services/mobile-application" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Mobile Apps | Ra'Asis" />
        <meta name="twitter:description" content="Explore new avenues for your business or personal brand, partner with us in creating a robust, dynamic and secure mobile application." />
        <meta name="twitter:image" content="/assets/raasis_logo.png" />
      </Helmet>
      <section className="about-introduction hire-dev-intro software-dev">
      <div className="about-intro-left hire-dev-left software-dev-left">
        <h4><Link to='/'><span>Home</span></Link> / <Link to='#'>Services</Link> / Mobile Application Development</h4>
        <div className='hire-dev-headings software-dev-headings'>
          <h3>A Mobile App Custom Tailored Just For You</h3>
          <h1>Transform Your Innovative Mobile App Idea Into A Scalable, Native Cross-Platform Reality</h1>
        </div>
        <div className='hire-dev-text software-dev-text'>
          <p>Explore new avenues for your business or personal brand, partner with us in creating a robust, dynamic and secure mobile application. Our app developers, combine process-oriented development with various technology stacks and follow the latest trends in mobile app design to create amazing user experiences.</p>
        </div>
      </div>
      <div className="about-intro-right hire-dev-right software-dev-right">
        <div className='hire-dev-box software-dev-box'>
          <div className='hire-dev-box-cont software-dev-box-cont'>
            <div className='stack-icon'>
              <i className='material-symbols-sharp'>developer_mode</i>
            </div>
            <h3>Mobile<br />Application<br />Development</h3>
            <h3>STARTS AT<br /><br /><span id='larger'>$100</span></h3>
          </div>
          <div className='hire-dev-btn software-dev-btn'>
              <Link to="/contact" onClick={handleLinkClick}>Get Mobile App</Link>
          </div>
        </div>
        <div className='not-decided'>
          <p>Haven't made up your mind yet on which service your business needs ?</p>
          <Link to='/contact' onClick={handleLinkClick}>Click here to book a free consultation</Link>
        </div>
      </div>
    </section>

    <div className="technologies-section services-technologies">
      <div className='technologies-intro services-technologies-intro'>
        <h2>We Build & Deploy Across The Tech Stack</h2>
        <p>Our team of expert designers, developers, and engineers are highly skilled in both classic and modern technologies.</p>
      </div>
      
      <nav className="technologies-nav services-tech-nav">
        <ul>
            {categories.map(category => (
                <li key={category.id}>
                    <Link
                        to="#"
                        className={selectedCategory.id === category.id ? 'active' : ''}
                        onClick={() => setSelectedCategory(category)}
                    >
                        {category.name}
                    </Link>
                </li>
            ))}
        </ul>
      </nav>
      
      <div className="technologies-images services-tech-img">
        {selectedCategory.images.map((image, index) => (
            <div key={index} className="technology-image">
                <img src={`/assets/${image}`} alt={selectedCategory.labels[index]} />
                <p>{selectedCategory.labels[index]}</p>
            </div>
        ))}
      </div>
    </div>
  </>
  );
}

export default MobileApplication;
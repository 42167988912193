import React from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom'

function ServicesOverviewSection() {

    const handleButtonClick = (event) => {

        const buttonText = event.target.textContent;
    
        ReactGA.event({
          category: 'User Interaction',
          action: 'Clicked A Button',
          label: buttonText,
        });
      };

  return (
    <div class="s-overview-main">
        <div class="s-overview-intro">
            <h2>Innovate For The Future With Our Services</h2>
            <p>
                The power of technology across multiple industries, we have just the right solutions to align with your business goals
            </p>
        </div>
        <div className="services-overview">
      
            {/* Software Dev*/}
            <div className="service">
                <div className="service-icon">
                    <span className="material-symbols-sharp">developer_mode_tv</span>
                </div>
                <h3>Software Development</h3>
                <p>Custom software solutions for your business</p>
                <div className="service-btn">
                    <Link to='/services/software-development'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*Mobile Apps*/}
            <div className="service">
                <div className="service-icon">
                    <span className="material-symbols-sharp">developer_mode</span>
                </div>
                <h3>Mobile App Development</h3>
                <p>A mobile app tailored specifically for you</p>
                <div className="service-btn">
                    <Link to='/services/mobile-application'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*MVP Development*/}
            <div className="service">
                <div className="service-icon">
                    <span className="material-symbols-sharp">rocket_launch</span>
                </div>
                <h3>MVP Development</h3>
                <p>Get a Minimum Viable Product (faster returns)</p>
                <div className="service-btn">
                    <Link to='/services/mvp-development'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*Website Design & Development*/}
            <div className="service">
                <div className="service-icon">
                    <span className="material-symbols-sharp">devices_other</span>
                </div>
                <h3>Website Design & Development</h3>
                <p>A static or dynamic website for your business</p>
                <div className="service-btn">
                    <Link to='/services/website-design'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*M & C Management*/}
            <div className="service">
                <div className="service-icon">
                    <span className="material-symbols-sharp">handyman</span>
                </div>
                <h3>Maintenance & Management</h3>
                <p>Expert analysis maintenance and management</p>
                <div className="service-btn">
                    <Link to='/services/maintenance'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*Computer Architecture*/}
            <div className="service">
                <div className="service-icon">
                    <span className="material-symbols-sharp">computer</span>
                </div>
                <h3>Computer Architecture</h3>
                <p>Computer architecture solutions and fixes</p>
                <div className="service-btn">
                    <Link to='/services/computer-architecture'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*Web Application*/}
            <div className="service bottom-mover">
                <div className="service-icon">
                    <span className="material-symbols-sharp">stacks</span>
                </div>
                <h3>Web Application Development</h3>
                <p>A full stack web application for your business</p>
                <div className="service-btn">
                    <Link to='/services/web-application'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*Software Proj. Management*/}
            <div className="service bottom-mover">
                <div className="service-icon">
                    <span className="material-symbols-sharp">monitoring</span>
                </div>
                <h3>Software Project Management</h3>
                <p>Professional management for your project</p>
                <div className="service-btn">
                    <Link to='/services/software-project'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>

            {/*Graphics*/}
            <div className="service bottom-mover respo-below520">
                <div className="service-icon">
                    <span className="material-symbols-sharp">design_services</span>
                </div>
                <h3>Graphics Design</h3>
                <p>Captivating visuals for lasting impressions</p>
                <div className="service-btn">
                    <Link to='/services/graphics-design'>
                        <button  onClick={handleButtonClick}>Learn More</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ServicesOverviewSection;
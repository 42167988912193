import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

function IntroductionSection(){
  const images = [
    '/assets/robust_secure.png',
    '/assets/quality_assured.png',
    '/assets/tech_future.png'
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Control interval as needed

    return () => clearInterval(intervalId);
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };


    return(
        <div className="introduction-container">
            {/* Left Section (Image Carousel) */}
            <div className="left-section">
              <div className="img-container">
                <img src={images[currentImageIndex]} alt="An illustration of Robust and Secure Software developed by Ra'Asis Software" />
              </div>
            </div>
            
            {/* Right Section (Rotating Text) */}
            <div className="right-section">
                <RotatingHeaderText />
                <div className="intro-paragraph">
                  <p>
                    We design, build, deploy and manage custom software as well as digital products and tools that are utilised in a number of industries. Are you an individual, a startup or even an established market leader in your industry ? Ra'Asis could be your trusted IT partner today. Together we will tap into the power of digital innovation and craft your success story. Our development approach combines strict Agile Software Engineering processes with efficient Continuous Integration/Continuous Development methods.
                  </p>
                </div>

                {/* Buttons Section */}
                <div className="intro-buttons">
                  <Link to="/contact" id="dev_team" onClick={handleLinkClick}>
                    Get Your Dev Team
                  </Link>
                  <Link to="/about-us" id="more_about" onClick={handleLinkClick}>
                    More About Ra'Asis
                  </Link>
                </div>
            </div>
        </div>
    );
}

function RotatingHeaderText(){
    const phrases = [
        'Robust & Secure',
        'Quality Assured',
        'Technology For The Future',
      ];
    
      const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    
      useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 10000); // Will adjust accordingly later
    
        return () => clearInterval(intervalId);
      });
    
      return (
        <h1>
          {phrases[currentPhraseIndex]}
        </h1>
      );
}

export default IntroductionSection;

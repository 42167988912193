import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {

useEffect(() => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: "Privacy Policy | Ra'Asis",
    });
});

const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
        <Helmet>
            <title>Privacy Policy | Ra'Asis</title>
            <meta name="description" content="We are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website or services." />
        </Helmet>
        <div className='privacy-notice'>
            <h2>Our Privacy Policy</h2>
            <p>
                At Ra'Asis Software, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website or services.
            </p>
            <h3>Information We Collect</h3>
            <p>
                When you use our website or services, we may collect certain information from you, including:
            </p>
            <ul>
                <li>Name, email address, and any other contact information you provide through our contact form or blog subscription form.</li>
                <li>Information about your interactions with our website, such as pages visited, time spent on each page, and referring website.</li>
                <li>Data collected through cookies and similar technologies. These technologies help us improve your browsing experience and provide personalized content.</li>
            </ul>
            <h3>How We Use Your Information</h3>
            <p>
                We use the information we collect for various purposes, including:
            </p>
            <ul>
                <li>To respond to your inquiries and provide customer support.</li>
                <li>To send you updates, newsletters, and marketing communications if you have subscribed to our blog.</li>
                <li>To analyze website traffic and improve our website and services.</li>
                <li>To comply with legal obligations.</li>
            </ul>
            <h3>Blogs & Newsletters</h3>
            <p>
                Ra'Asis provides opportunities to sign-up for our email newsletters and blog updates sent out periodically. You can subscribe to them by sharing your email address and confirming by clicking on the confirmation link sent out to you. In this process, we collect your email address, IP address, and time of subscription. We discreetly use this information for the distribution of email newsletters and blog updates only. You can cancel the subscription at any time with the help of unsubscribe instructions followed in each newsletter. Once you unsubscribe, all the personal information is deleted permanently from our system. We periodically post blogs on our website that are accessed publicly. You can freely comment on your reviews, suggestions, or start discussions in the comment section of the blogs. We want to inform you that any information that is shared by you in the comments can be read, shared, or collected by anyone who reads them.
            </p>
            <h3>Google Analytics</h3>
            <p>
                We use the third-party service, Google Analytics, to analyze website traffic and user behavior. This helps us in improving the quality of services, content, and communication that we provide to our users. We would like to assure you that no personal data is stored in this process and you can verify it with the Google Analytics policy as well.
            </p>
            <h3>Cookie Policy</h3>
            <p>
                Our website uses cookies to enhance your browsing experience. By using our website, you consent to the use of cookies in accordance with our Privacy Policy.
            </p>
            <h3>How To Control Cookies</h3>
            <p>
                You can control cookies through your browser settings. Many browsers have a help feature that will tell you how to accept cookies, disable cookies, or be notified when you receive a new cookie. Please note that disabling cookies may affect your experience on our website and therefore we recommend you leave them on.
            </p>
            <h3>Consent (Acceptance Of Policy)</h3>
            <p>
                You agree to our privacy policy by accessing this website or using services provided by this website. Please do not use our website or the services provided by this website if you do not agree with any of the terms. We also reserve the right, at our discretion, to amend, modify, add, or remove elements of this policy by simply posting such changes, updates, or modifications on the website. Continued use of our site after the policy terms have been changed indicates your acceptance of these terms.
            </p>
            <h3>Updates To This Privacy Policy</h3>
            <p>
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            </p>
            <h3>Contact Us</h3>
            <p>
                If you have any questions or concerns about our Privacy Policy, please contact us at <Link to='mailto:privacy@raasissoftware.com' onClick={handleLinkClick}>privacy@raasissoftware.com.</Link>
            </p>
        </div>
    </>
  );
}

export default PrivacyPolicy;

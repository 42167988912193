import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import WhyChooseSection from './WhyChooseSection';

const HireADeveloper = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Hire A Developer | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>Hire A Developer | Ra'Asis</title>
        <meta name="description" content="We have deep industry expertise and a talented pool of seasoned designers and developers at your project's disposal." />
        <meta property="og:title" content="Hire A Developer | Ra'Asis" />
        <meta property="og:description" content="We have deep industry expertise and a talented pool of seasoned designers and developers at your project's disposal." />
        <meta property="og:image" content="/assets/quality_assured.png" />
        <meta property="og:url" content="https://raasissoftware.com/hire-a-developer" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Hire A Developer | Ra'Asis" />
        <meta name="twitter:description" content="We have deep industry expertise and a talented pool of seasoned designers and developers at your project's disposal." />
        <meta name="twitter:image" content="/assets/quality_assured.png" />
        <meta name="twitter:card" content="/assets/quality_assured.png" />
      </Helmet>
      <section className="about-introduction hire-dev-intro">
      <div className="about-intro-left hire-dev-left">
        <h4><Link to='/'><span>Home</span></Link> / Hire A Developer</h4>
        <div className='hire-dev-headings'>
          <h3>A Full-Stack Developer For Your Project</h3>
          <h1>Beef Up Your Team With Front-End & Back-End Expertise Delivered In One Package</h1>
        </div>
        <div className='hire-dev-text'>
          <p>We have deep industry expertise and a talented pool of seasoned full-stack developers at your project's disposal. For a project you are yet to start, or one that is ongoing that needs more devpower, this is exactly what you require. Our rigorous process of hiring experienced developers ensures we complement our tech stack and in turn bring the best industry knowledge for the holistic technological development of your brand.</p>
        </div>
      </div>
      <div className="about-intro-right hire-dev-right">
        <div className='hire-dev-box'>
          <div className='hire-dev-box-cont'>
            <div className='stack-icon'>
              <i className='material-symbols-sharp'>stacks</i>
            </div>
            <h3>A Single<br />Full-Stack<br />Developer</h3>
            <h3>Rate Is<br /><br /><span id='larger'>$15/</span><span id='smaller'>Hr</span></h3>
          </div>
          <div className='hire-dev-btn'>
              <Link to="/contact" onClick={handleLinkClick}>Get Full Stack Dev.</Link>
          </div>
        </div>
        <div className='not-decided'>
          <p>Haven't made up your mind yet on what your project requires ?</p>
          <Link to='/contact' onClick={handleLinkClick}>Click here to book a free consultation</Link>
        </div>
      </div>
    </section>

    <WhyChooseSection />
  </>
  );
}

export default HireADeveloper;
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AboutIntroduction from './about-us/AboutIntroduction';
import AboutDetailed from './about-us/AboutDetailed';
import OurTeam from './about-us/OurTeam';

const AboutUs = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "About Us | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>About Us | Ra'Asis</title>
        <meta name="description" content="We are dedicated to delivering innovative digital solutions that will significantly scale up your brand or business and streamline your operations." />
        <meta property="og:title" content="About Us | Ra'Asis" />
        <meta property="og:description" content="Our mission is to empower businesses with cutting-edge technology and reinforce their development teams with Software Engineering expertise." />
        <meta property="og:image" content="/assets/quality_assured.png" />
        <meta property="og:url" content="https://raasissoftware.com/about-us" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="About Us | Ra'Asis" />
        <meta name="twitter:description" content="Our mission is to empower businesses with cutting-edge technology and reinforce their development teams with Software Engineering expertise." />
        <meta name="twitter:image" content="/assets/quality_assured.png" />
        <meta name="twitter:card" content="/assets/quality_assured.png" />
      </Helmet>
      <AboutIntroduction />
      <AboutDetailed />
      <OurTeam />
      <section className='lead-gen-mini'>
        <div className='lead-gen-mini-main'>
          <div className='lead-gen-mini-heading'>
            <h2>We Foster Brand Growth With A Combination Of A Creative Design & Development Team, Modern Technologies & Agile Development Processes</h2>
          </div>
          <div className='lead-gen-mini-btn'>
            <Link to='/contact' onClick={handleLinkClick}>Let's Discuss Your Brand's Next Project<i className='bx bxs-hand-right'></i></Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;

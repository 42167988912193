import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Services = () => {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Services | Ra'Asis",
    });
  });

  return <h2>Services Component</h2>;
}

export default Services;

import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {

  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [icon, setIcon] = useState('bx bx-menu');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
    setIcon(isMenuOpen ? 'bx bx-menu' : 'bx bx-x');
    const newIcon = isMenuOpen ? 'keyboard_double_arrow_down' : 'keyboard_double_arrow_right';
    const menuItems = document.querySelectorAll('.material-symbols-sharp.right-side');
    menuItems.forEach(item => item.innerHTML = newIcon);
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
    setIcon('bx bx-menu'); // Change the icon back to the menu icon
  };

  useEffect(() => {
    const disableRightClick = () => {
      const handleRightClick = (event) => {

        // Check if the right-click event originates from an image element
        const target = event.target;
        const isImageElement = target.tagName.toLowerCase() === 'img';

        // If the right-click event originates from an image element, prevent default behavior
        if (isImageElement) {
          event.preventDefault();
        } else {
          // If the right-click event originates from any other element, check for background images
          const allElements = document.querySelectorAll('*');
          allElements.forEach(element => {
            const backgroundImage = getComputedStyle(element).backgroundImage;
            if (backgroundImage.includes('.png') || backgroundImage.includes('.jpg')) {
              event.preventDefault();
            }
          });
        }
      };

      document.addEventListener('contextmenu', handleRightClick);

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('contextmenu', handleRightClick);
      };
    };

    disableRightClick();
  }, []);

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };


  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
        <div className="logo">
            <Link to="/">
                <img src="/assets/raasis_logo.svg" alt="Ra'Asis Software logo" />
            </Link>
        </div>
        <i className={icon} id='menu_icon' onClick={toggleMenu}></i>
        <nav className={isMenuOpen ? 'active' : ''} id='main_nav'>
            <ul>
                <li>
                    <Link to='#'><span className="material-symbols-sharp left-side">linked_services</span>Services<span className="material-symbols-sharp right-side">keyboard_double_arrow_down</span>
                    </Link>
                    <ul className="submenu">
                        <li><Link to="/services/software-development" className={location.pathname === '/services/software-development' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">developer_mode_tv</span>Software Development</Link></li>
                        <li><Link to="/services/website-design" className={location.pathname === '/services/website-design' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">devices_other</span>Website Design & Development</Link></li>
                        <li><Link to="/services/web-application" className={location.pathname === '/services/web-application' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">stacks</span>Web Application Development</Link></li>
                        <li><Link to="/services/mobile-application" className={location.pathname === '/services/mobile-application' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">developer_mode</span>Mobile Application Development</Link></li>
                        <li><Link to="/services/maintenance" className={location.pathname === '/services/maintenance' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">handyman</span>Maintenance & Content Management</Link></li>
                        <li><Link to="/services/software-project" className={location.pathname === '/services/software-project' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">monitoring</span>Software Project Management</Link></li>
                        <li><Link to="/services/mvp-development" className={location.pathname === '/services/mvp-development' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">rocket_launch</span>MVP Development</Link></li>
                        <li><Link to="/services/computer-architecture" className={location.pathname === '/services/computer-architecture' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">computer</span>Computer Architecture</Link></li>
                        <li><Link to="/services/graphics-design" className={location.pathname === '/services/graphics-design' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">design_services</span>Graphics Design</Link></li>

                        <div className='tohide-rotating-text'>
                          <RotatingText />
                        </div>
                    </ul>
                </li>
                <li>
                    <Link to="/about-us" className={location.pathname === '/about-us' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp left-side">query_stats</span>About Us<span className="material-symbols-sharp right-side">keyboard_double_arrow_down</span>
                    </Link>
                    <ul className="submenu">
                        <li><Link to="/about-us" className={location.pathname === '/about-us/our-culture' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">house_with_shield</span>Our Culture</Link></li>
                        <li><Link to="/about-us/our-team" className={location.pathname === '/about-us/our-team' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">groups</span>Our Team</Link></li>
                        <li><Link to="/about-us/blog" className={location.pathname === '/about-us/blog' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">article</span>Blog</Link></li>
                    </ul>
                </li>
                <li><Link to="/industries" className={location.pathname === '/industries' ? 'active' : ''}onClick={closeMenu}><span className="material-symbols-sharp left-side">precision_manufacturing</span>Industries</Link></li>
                <li><Link to="/built-by-us" className={location.pathname === '/built-by-us' ? 'active' : ''}onClick={closeMenu}><span className="material-symbols-sharp left-side">deployed_code</span>Built By Us</Link></li>
                <li>
                    <Link to="/hire-a-developer" className={location.pathname === '/hire-a-developer' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp left-side">deployed_code_account</span>Hire A Developer<span className="material-symbols-sharp right-side">keyboard_double_arrow_down</span>
                    </Link>
                    <ul className="submenu">
                        <li><Link to="/hire-a-developer/front-end" className={location.pathname === '/hire-a-developer/front-end' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">flip_to_front</span>Front-End</Link></li>
                        <li><Link to="/hire-a-developer/back-end" className={location.pathname === '/hire-a-developer/back-end' ? 'active' : ''} onClick={closeMenu}><span className="material-symbols-sharp sub-side">flip_to_back</span>Back-End</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
        <div className="cta-button">
            <Link to="/contact" onClick={handleLinkClick}>Let's Get Started</Link>
        </div>
    </header>
  );
}

  function RotatingText() {
    const phrases = [
      'We build and deploy across the tech stack',
      'We strictly adhere to industry-proven Agile SE processes',
      'We guarantee robust and secure digital products',
    ];
  
    const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * phrases.length);
        setCurrentPhrase(phrases[randomIndex]);
      }, 10000);
  
      return () => clearInterval(intervalId);
    });
  
    return (
      <div className="submenu-text">
        <p>{currentPhrase}</p>
      </div>
    );
  }

export default Header;

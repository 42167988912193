import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async'
import caseStudiesData from '../data/caseStudiesData';

const CaseStudy = () => {
    useEffect(() => {
        ReactGA.send({
          hitType: "pageview",
          page: window.location.pathname,
          title: `Case Study | ${caseStudy.title}`,
        });
      });

      const handleLinkClick = (event) => {

        const linkText = event.target.textContent;
    
        ReactGA.event({
          category: 'User Interaction',
          action: 'Clicked A Btn Link',
          label: linkText,
        });
      };

  const { id } = useParams();
  const caseStudy = caseStudiesData.find(cs => cs.id === id);

  if (!caseStudy) {
    return <div>Case Study Not Found</div>;
  }

  return (
    <>
        <Helmet>
            <title>Case Study | {caseStudy.title}</title>
            <meta name="description" content={caseStudy.shortDescription || "Explore our case studies for detailed insights on our innovative creations and successful projects."} />
            <meta property="og:title" content={`Case Study | ${caseStudy.title}`} />
            <meta property="og:description" content={caseStudy.shortDescription || "Explore our case studies for detailed insights on our innovative creations and successful projects."} />
            <meta property="og:image" content={caseStudy.laptopImage || caseStudy.image} />
            <meta property="og:url" content={`https://raasissoftware.com/case-study/${caseStudy.id}`} />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content={`Case Study | ${caseStudy.title}`} />
            <meta name="twitter:description" content={caseStudy.shortDescription || "Explore our case studies for detailed insights on our innovative creations and successful projects."} />
            <meta name="twitter:image" content={caseStudy.laptopImage || caseStudy.image} />
        </Helmet>

        {/* Introduction Section */}
        <div className="case-study-intro about-introduction">
            <div className="case-intro-left about-intro-left hire-dev-left">
                <div className='mini-navigation'>
                    <h4><Link to='/'><span>Home</span></Link> / <Link to='/built-by-us'>Built By Us</Link> / {caseStudy.title}</h4>
                </div>
                <div className='case-intro-headings'>
                    <h3>{caseStudy.industry}</h3>
                    <h1>{caseStudy.title}</h1>
                </div>
                <div className='case-intro-description'>
                    <p>{caseStudy.shortDescription}</p>
                </div>
            </div>
            <div className="case-intro-right about-intro-right">
                <div className='case-intro-image'>
                    <img src={caseStudy.image} alt={caseStudy.title} />
                </div>
            </div>
        </div>

        {/* Cards Section */}
        <div className='why-choose-section case-study-overview'>
            <div className="case-study-cards why-choose-reasons">
                <div className="reason-card" id='light_orange'>
                    <div className="reason-icon"><i className='material-symbols-sharp'>precision_manufacturing</i></div>
                    <h3>Industry</h3>
                    <h4>{caseStudy.industry}</h4>
                </div>
                <div className="reason-card" id='dark_orange'>
                    <div className="reason-icon"><i className='bx bxl-microsoft-teams'></i></div>
                    <h3>Team</h3>
                    <h4>{caseStudy.team}</h4>
                </div>
                <div className="reason-card" id='light_blue'>
                    <div className="reason-icon"><i className='bx bxs-calendar'></i></div>
                    <h3>Duration</h3>
                    <h4>{caseStudy.duration}</h4>
                </div>
                <div className="reason-card" id='light_orange'>
                    <div className="reason-icon"><i className='bx bxs-package'></i></div>
                    <h3>Launched</h3>
                    <h4>{caseStudy.launched}</h4>
                </div>
            </div>
            <div className='final-reason'>
                <div className="reason-card" id='dark_orange'>
                    <div className="reason-icon"><i className='bx bxs-star'></i></div>
                    <h3>Rating</h3>
                    <h4>{caseStudy.rating}</h4>
                </div>
            </div>
        </div>

        {/* Large Image Section */}
        <div className="laptop-image-section">
          <img src={caseStudy.laptopImage} alt={caseStudy.title} />
        </div>

        {/* About The Project Section */}
        <div className="case-study-about">
            <h3>About The Project</h3>
            <h2>Project Overview</h2>
            <p>{caseStudy.projectOverview}</p>
        </div>

        {/* Project Features Section */}
        <div className="case-study-features">
            <h2>Key Features</h2>
            <ul>
                {caseStudy.features.map((feature, index) => (
                    <li key={index}><i className='bx bx-list-check'></i>{feature}</li>
                ))}
            </ul>
        </div>

        {/* Process Section */}
        {caseStudy.isDevelopmentProject && (
        <div className="case-study-process">
          <div className='process-intro'>
            <h2>Process</h2>
          </div>
          <div className='process-details'>
            <div className="process-stage">
              <h3>Planning & Analysis</h3>
              <ul>
                <li><i className='bx bx-list-check'></i>Requirement Gathering</li>
                <li><i className='bx bx-list-check'></i>User Research</li>
                <li><i className='bx bx-list-check'></i>Information Architecture</li>
                <li><i className='bx bx-list-check'></i>Wireframing & Prototyping</li>
                <li><i className='bx bx-list-check'></i>Technology Stack Selection</li>
              </ul>
            </div>
            <div className="process-stage">
              <h3>Design & Development</h3>
              <ul>
                <li><i className='bx bx-list-check'></i>UX/UI Design</li>
                <li><i className='bx bx-list-check'></i>Front-End Development</li>
                <li><i className='bx bx-list-check'></i>Back-End Development</li>
                <li><i className='bx bx-list-check'></i>API Development</li>
                <li><i className='bx bx-list-check'></i>Testing</li>
              </ul>
            </div>
            <div className="process-stage">
              <h3>Deployment & Maintenance</h3>
              <ul>
                <li><i className='bx bx-list-check'></i>Deployment</li>
                <li><i className='bx bx-list-check'></i>Monitoring & Security</li>
                <li><i className='bx bx-list-check'></i>Continuous Improvement</li>
                <li><i className='bx bx-list-check'></i>Maintenance & Support</li>
              </ul>
            </div>
          </div>
        </div>
        )}

        {/* Challenges Section */}
        <div className="case-study-challenges">
            <h3>Challenges We Faced</h3>
            <h2>Project Problems</h2>
            {caseStudy.challenges.map((challenge, index) => (
                <div key={index}>
                    <h3>{challenge.heading}</h3>
                    <p>{challenge.description}</p>
                </div>
            ))}
        </div>

        {/* Large Image Section */}
        <div className="mobile-image-section">
          <img src={caseStudy.mobileImage} alt={caseStudy.title} />
        </div>

        {/* Solutions Section */}
        <div className="case-study-solutions">
            <h3>How We Solved The Problems</h3>
            <h2>The Solutions</h2>
            {caseStudy.solutions.map((solution, index) => (
                <div key={index}>
                    <h3>{solution.heading}</h3>
                    <p>{solution.description}</p>
                </div>
            ))}
        </div>

        {/* Large Image Section */}
        <div className="tablet-image-section">
          <img src={caseStudy.tabletImage} alt={caseStudy.title} />
        </div>

        {/* Impact Section */}
        <div className="case-study-impact">
            <h3>Last Milestone</h3>
            <h2>The Impact</h2>
            {caseStudy.impact.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </div>

        {/* Technology Stack Section */}
        <div className="case-study-tech-stack">
            <h2>Technology Stack</h2>
            <p>{caseStudy.techStackDescription}</p>
            <div className="tech-stack-cards">
                {caseStudy.techStack.map((tech, index) => (
                    <div key={index} className="tech-stack-card">
                        <img src={tech.logo} alt={tech.name} />
                        <h4>{tech.name}</h4>
                    </div>
                ))}
            </div>
        </div>

        <div className='lead-gen-mini case-study-cta'>
        <div className='lead-gen-mini-main'>
          <div className='lead-gen-mini-heading'>
            <h2>Grow Your Brand By Combining A Team Of Creative Designers, Expert Developers, Modern Technologies & Agile Development Processes</h2>
          </div>
          <div className='lead-gen-mini-btn'>
            <Link to='/contact' onClick={handleLinkClick}>Let's Discuss Your Next Project<i className='bx bxs-hand-right'></i></Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaseStudy;

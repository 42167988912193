const caseStudiesData = [
    {
      id: 'moocourses',
      title: 'Transfrontières MooCourses Project',
      industry: 'Education',
      shortDescription: 'Transfrontières\' online learning platform with customised EdTech tools through which learners and tutors interact and share knowledge.',
      image: '/assets/moocourses_proj_long.gif',
      team: '5 Members',
      duration: '3 months',
      launched: 'April 2024',
      rating: '4.8/5',
      laptopImage: '/assets/moocourses_laptop.gif',
      projectOverview: 'The Transfrontières MooCourses Project aimed to revolutionize online learning by providing a robust platform with customized EdTech tools. It facilitates interaction and knowledge sharing among learners and tutors, making the Education and Training service at Transfrontières Agencies Limited more accessible and effective. Through the platform, clients can now learn French Language for Professional Purposes, ICT, Music, and Research. We built a highly scalable system to allow for additional education and training modules which are due to be added over the next few months. MooCourses has been highly rated by users for its comprehensive approach to online learning and its innovative features.',
      features: [
        'Custom EdTech Tools',
        'Interactive Learning Modules',
        'Real-Time Collaboration',
        'Highly Scalable',
        'Secure User Authentication'
      ],
      challenges: [
        {
            heading: 'Technical Constraints',
            description: 'Implementing real-time collaboration features with low latency was a significant technical challenge that required careful optimization.'
        },
        {
            heading: 'User Engagement',
            description: 'Ensuring consistent user engagement in a virtual learning environment was difficult, necessitating the development of interactive and gamified elements.'
        }
      ],
      solutions: [
        {
            heading: 'Optimization Techniques',
            description: 'We used advanced optimization techniques and robust server architecture to minimize latency and ensure seamless real-time collaboration.'
        },
        {
            heading: 'Interactive & Gamified Elements',
            description: 'We integrated interactive and gamified elements such as quizzes, badges, and leaderboards to enhance user engagement and make learning more enjoyable.'
        }
      ],
      mobileImage: '/assets/moocourses_mobile.webp',
      tabletImage: '/assets/moocourses_tablet.webp',
      impact: [
        'The MooCourses platform has significantly improved the accessibility of education and training for individual students and businesses across Kenya and other parts of the world, allowing them to learn from anywhere. The real-time collaboration features have enabled more effective tutor-student interactions, thereby enhancing the overall learning experience. Including highly interactive modules in the platform has significantly increased user engagement, with more students completing their courses. Through this project Transfrontieres Agencies Limited experienced a massive transformation in how they offer their Education and Training service to their clients. It perfectly aligned with their objective of full service digitalization.'
      ],
      techStackDescription: 'For this project, we utilized a robust technology stack. HTML5 and CSS3 were used for front-end development to create a responsive and user-friendly interface. PHP was employed for back-end development, handling server-side logic and operations. MySQL was chosen as the database management system to efficiently store and manage user and course data.',
      techStack: [
        { name: 'HTML5', logo: '/assets/html.svg' },
        { name: 'CSS3', logo: '/assets/css.svg' },
        { name: 'PHP', logo: '/assets/php.svg' },
        { name: 'MySQL', logo: '/assets/mysql_server.svg' }
      ],
      isDevelopmentProject: true
    },
    {
      id: 'stormerz',
      title: 'MMU Softball Graphics Project',
      industry: 'Sports & Gaming',
      shortDescription: 'Logo and home jersey designs for Multimedia University of Kenya, Softball & Baseball team to foster brand identity and recognition.',
      image: '/assets/stormerz_proj_long.gif',
      team: '2 Members',
      duration: '1 Month',
      launched: 'March 2024',
      rating: '4.9/5',
      laptopImage: '/assets/stormerz_logo.png',
      projectOverview: 'The MMU Softball Graphics Project focused on designing a new logo and home jersey for the Multimedia University of Kenya\'s Softball & Baseball team. The main objective was to enhance the team\'s brand identity and recognition. The new designs have been well-received, and they have not only improved the team\'s visual identity but also fostered a stronger sense of unity and pride among the team members.',
      features: [
        'Unique Logo Design',
        'Custom Jersey Design',
        'Brand Identity Development',
        'High-Quality Graphics'
      ],
      challenges: [
        {
          heading: 'Brand Consistency',
          description: 'Maintaining brand consistency across various graphic elements was one of the major challenges in this project, and it required a cohesive design strategy.'
        },
        {
          heading: 'Feedback Integration',
          description: 'Incorporating feedback from multiple stakeholders within a short timeframe was another challenge that posed difficulties in the design iteration process.'
        }
      ],
      solutions: [
        {
          heading: 'Cohesive Design Strategy',
          description: 'We formulated a comprehensive design guideline to ensure brand consistency across all graphic elements and applied it rigorously throughout the process.'
        },
        {
          heading: 'Efficient Feedback Process',
          description: 'We established an efficient feedback loop, utilizing collaborative tools to gather and integrate feedback effectively and within the designated timelines.'
        }
      ],
      mobileImage: '/assets/stormerz_jersey.png',
      tabletImage: '/assets/stormerz_mockup.png',
      impact: [
        'This project has received positive feedback from both the team and the university sports representatives, with many praising the high quality and professionalism of the designs. The new logo and jersey designs have greatly enhanced the team\'s brand identity, making them easily recognizable and fostering a sense of pride among team members. The enhanced brand identity has also helped in attracting new members to the team, contributing to its growth and success.'
      ],
      techStackDescription: 'In the execution of this project, we employed a diverse set of design tools. Adobe Illustrator was used for creating detailed vector graphics for the jersey and logo designs. Adobe Photoshop was utilized for editing the resulting images and enhancing visual elements. Canva was chosen for adding a finishing touch to the designs, its ease of use in creating and sharing design templates and creating mockups was perfect.',
      techStack: [
        { name: 'Adobe Illustrator', logo: '/assets/adobe_illustrator.svg' },
        { name: 'Adobe Photoshop', logo: '/assets/adobe_photoshop.svg' },
        { name: 'Canva', logo: '/assets/canva.svg' }
      ]
    },
    {
      id: 'zeway-builders-ecommerce',
      title: 'Zeway Builders eCommerce Platform',
      industry: 'Building & Construction',
      shortDescription: 'An innovative eCommerce platform for building materials, equipment and contractor services. The project aimed to streamline purchasing and service connections for Zeway Builders\' clients.',
      image: '/assets/zeway_proj_long.gif',
      team: '3 Members',
      duration: '4 Months',
      launched: 'June 2024',
      rating: '4.9/5',
      laptopImage: '/assets/zeway_architects.png',
      projectOverview: 'We undertook this project for Zeway Builders with the aim to transform the building, construction and contractor services industry by providing a seamless online shopping and service booking experience. The platform allows clients to view prices for building materials and equipment, place orders and make secure payments online for their chosen items. It also provides a way for the clients to connect with contractors such as architects, site managers, quality inspectors, plumbers, and electricians. As a matter of fact, almost all the essential elements required to start, continue or finish a construction project can be accessed quickly and efficiently through this Ra\'Asis software solution.',
      features: [
        'Dynamic & Responsive User Interfaces',
        'Real-Time Order Processing',
        'Secure Online Payment Integration',
        'Product & Contractor Search/Filter Functionality',
        'Price Calculator Inclusive of Delivery Costs',
        'Responsive Design For Multiple Screen Views',
        'Comprehensive Product & Contractor Categories'
      ],
      challenges: [
        {
        heading: 'Data Management',
        description: 'Efficiently handling a large volume of data, comprising of product listings, user accounts, and orders, was challenging and required robust data management solutions.'
        },
        {
        heading: 'Security Concerns',
        description: 'Ensuring the security of user data and transactions was paramount, necessitating the implementation of advanced security measures while maintaining compliance with data protection regulations.'
        }
      ],
      solutions: [
        {
        heading: 'Scalable Database Solution',
        description: 'We implemented MongoDB, a flexible NoSQL database, to efficiently manage and retrieve complex data structures which satisfied the need for scalability and reliability.'
        },
        {
        heading: 'Enhanced Security Features',
        description: 'We integrated Stripe and IntaSend(for local users) for secure online payments and employed comprehensive security protocols to safeguard user data and transactions.'
        }
      ],
      mobileImage: '/assets/zeway_finishing.png',
      tabletImage: '/assets/zeway_foundation.png',
      impact: [
        'The Zeway Builders eCommerce platform has revolutionized the way customers purchase building materials and construction equipment. It has made connecting with contractors way easier, providing a seamless and efficient online experience throughout the entire process. Integration of real-time order processing and secure payment systems has significantly increased customer satisfaction and trust. Not only is the platform robust and secure, but also responsive and user-friendly making it accessible to a broader audience. This has enabled Zeway Builders to extend their reach, enhance user engagement and foster a culture of client retention.'
      ],
      techStackDescription: 'For the design and development of this project, we utilized a robust technology stack to ensure a scalable, secure, and feature-rich platform. React, HTML5, CSS3 and JavaScript were chosen for building dynamic and responsive user interfaces. Node.js provided a scalable and asynchronous backend, while MongoDB offered flexibility and scalability for managing complex data structures.',
      techStack: [
          { name: 'React', logo: '/assets/react.svg' },
          { name: 'HTML5', logo: '/assets/html.svg' },
          { name: 'CSS3', logo: '/assets/css.svg' },
          { name: 'JavaScript', logo: '/assets/javascript.svg' },
          { name: 'Node.js', logo: '/assets/node.svg' },
          { name: 'MongoDB', logo: '/assets/mongodb.svg' }
      ],
      isDevelopmentProject: true
    },
    {
      id: 'transfrontieres-agencies-website',
      title: 'Transfrontières Agencies Website',
      industry: 'Multi-Service',
      shortDescription: 'A comprehensive website for Transfrontières Agencies Limited to connect clients with a wide range of integrated services, including Communications, Education & Training, ICT, and Events.',
      image: '/assets/transfro_proj_long.gif',
      team: '2 Members',
      duration: '2 Months',
      launched: 'July 2023',
      rating: '4.7/5',
      laptopImage: '/assets/transfro_laptop.gif',
      projectOverview: 'The Transfrontières Agencies Website is an Integrated Services platform that was developed to streamline the connection between the agency and its clients, primarily businesses and brands. The website offers an easy-to-navigate interface where clients can book consultations and directly access digital solutions in Communications, Education & Training, ICT, and Events through secure links.',
      features: [
        'User-friendly Interface',
        'Online Booking System For Consultations',
        'Secure Access To Digital Solutions',
        'Service Categories: Communications, Education & Training, ICT, Events',
        'Responsive Design For All Screen Sizes'
      ],
      challenges: [
        {
          heading: 'Service Integration',
          description: 'Integrating all the services including their sub-services into a single cohesive platform required a robust and flexible architecture.',
        },
        {
          heading: 'User Experience',
          description: 'Ensuring a seamless user experience across diverse services and client needs was a complex task, necessitating meticulous design and testing.'
        },
        {
          heading: 'Security',
          description: 'Implementing secure access to digital solutions and ensuring data protection across all services was also one of the main challenges in this project.'
        }
      ],
      solutions: [
        {
          heading: 'Modular Architecture',
          description: 'We developed a modular architecture that allows for flexible integration of various services, making it easy to manage and scale the platform.'
        },
        {
          heading: 'Enhanced UX Design',
          description: 'Through user research and iterative design, we created an intuitive and seamless user experience that caters to individuals of all ages and businesses in different levels.'
        },
        {
          heading: 'Advanced Security Measures',
          description: 'We implemented secure links and robust authentication mechanisms to protect user data and ensure safe access to all the available digital solutions.'
        }
      ],
      mobileImage: '/assets/transfro_mobile.webp',
      tabletImage: '/assets/transfro_tablet.webp',
      impact: [
        'The integrated services on the website have significantly improved client engagement, providing a centralized platform for accessing all the offerrings of Transfrontières Agencies Ltd. The online booking system has streamlined all consultation processes, making it more efficient and convenient for Transfrontières\' clients.The secure linking to the available digital solutions per service have increased client trust and satisfaction, leading to positive feedback and higher retention rates.'
      ],
      techStackDescription: 'We used WordPress for a flexible CMS, with HTML, CSS, and JavaScript for front-end development. PHP was utilized for back-end processes, and MySQL for database management, ensuring a robust, scalable, and secure platform.',
      techStack: [
        { name: 'WordPress', logo: '/assets/wordpress.svg' },
        { name: 'HTML5', logo: '/assets/html.svg' },
        { name: 'CSS3', logo: '/assets/css.svg' },
        { name: 'JavaScript', logo: '/assets/javascript.svg' },
        { name: 'PHP', logo: '/assets/php.svg' },
        { name: 'MySQL', logo: '/assets/mysql_server.svg' }
      ],
      isDevelopmentProject: true
    },
    {
      id: 'oaksafrica',
      title: 'The Oaks Africa Foundation Project',
      industry: 'Non-Profit',
      shortDescription: 'An online platform for Oaks Africa Foundation to provide information about their mission, vision and community development projects and initiatives. It facilitates a seamless interface to run OAF scholarship and sponsorship programs online.',
      image: '/assets/oaksafrica_proj_long.gif',
      team: '2 Members',
      duration: '2 Months',
      launched: 'July 2023',
      rating: '4.6/5',
      laptopImage: '/assets/oaksafrica_laptop.gif',
      projectOverview: 'The Oaks Africa Foundation website project aimed to create a visually appealing, user-friendly, and responsive platform that aligned with the foundation\'s objectives. Through the platform, interested parties can get information about the foundation\'s agenda, mission, vision scholarship and sponsorship programs and many other OAF projects. It offers interactive ways for visitors to apply for scholarships, donate funds, apply for sponsorship programs, and start sponsoring. The project also included the development of a content management system (CMS) for easy content updates and maintenance, along with integrating basic and advanced SEO practices to enhance visibility in search engine results.',
      features: [
        'Responsive Design',
        'CMS For Easy Content Updates',
        'Dynamic Scholarship Application Forms',
        'Dynamic Sponsorship Application Forms',
        'Secure Donation & Sponsorship Payment Integrations',
        'Newsletter Subscription & Auto-Updates',
      ],
      challenges: [
        {
          heading: 'Content Management',
          description: 'Developing an intuitive CMS that allowed the foundation\'s team to easily update and manage content without deep technical expertise was one of the challenges encountered.'
        },
        {
          heading: 'User Engagement',
          description: 'Ensuring high user engagement and interaction through the website, particularly for applying to programs and donating to fund the programs.'
        }
      ],
      solutions: [
        {
          heading: 'User-Friendly CMS',
          description: 'We implemented a WordPress-based CMS, utilizing customized theme templates and features that provided easy content management by the foundation\'s team.'
        },
        {
          heading: 'Interactive Features',
          description: 'The team also developed interactive features such as application forms and donation integrations to enhance user engagement and interaction.'
        }
      ],
      mobileImage: '/assets/oaksafrica_mobile.webp',
      tabletImage: '/assets/oaksafrica_tablet.webp',
      impact: [
        'The website has significantly increased the foundation’s online visibility, leading to higher traffic and increased engagement with the community. The interactive scholarship and sponsorship applications, secure online donation payments and constant communication channels through newsletters and website adverts have simplified the process for users and elevated their experiences. The results are evident in more scholarship applications and donations as compared to when the process was only handled offline. The user-friendly CMS has empowered the foundation’s team to manage and update content efficiently without needing frequent technical support.'
      ],
      techStackDescription: 'For this OAF project, we utilized a robust technology stack to ensure a responsive, user-friendly, and highly functional website. The front-end was built using HTML, CSS, and JavaScript to create a dynamic and engaging user experience. The back-end was powered by WordPress, PHP, and MySQL, providing a robust and scalable platform for content management and data handling. IntaSend API was integrated for secure and efficient payment processing in online donations and sponsorship funding.',
      techStack: [
        { name: 'WordPress', logo: '/assets/wordpress.svg' },
        { name: 'HTML', logo: '/assets/html.svg' },
        { name: 'CSS', logo: '/assets/css.svg' },
        { name: 'JavaScript', logo: '/assets/javascript.svg' },
        { name: 'PHP', logo: '/assets/php.svg' },
        { name: 'MySQL', logo: '/assets/mysql_server.svg' }
      ],
      isDevelopmentProject: true
    },
    {
      id: 'mombasainfernos',
      title: 'Mombasa Infernos Branding & Graphics Design Project',
      industry: 'Sports & Gaming',
      shortDescription: 'Designing a new logo, home jersey, and social media graphics for the Mombasa Infernos Softball & Baseball Club.',
      image: '/assets/infernos_proj_long.gif',
      team: '2 Members',
      duration: 'Ongoing',
      launched: 'July 2023',
      rating: '5/5',
      laptopImage: '/assets/infernos_logo.png',
      projectOverview: 'The Mombasa Infernos project involves comprehensive branding and graphic design services for the softball and baseball club. We designed a new logo and home jersey for the last season, ensuring a fresh and dynamic visual identity. Additionally, we continue to create engaging graphics for their social media posts, helping to boost their online presence and fan engagement. This ongoing collaboration ensures that the Mombasa Infernos maintain a strong and consistent brand image both on and off the field.',
      features: [
        'Logo Design',
        'Home Jersey Design',
        'Social Media Graphics',
        'Brand Consistency',
        'Engaging Visual Content'
      ],
      challenges: [
        {
          heading: 'Brand Identity',
          description: 'Creating a cohesive and appealing brand identity that resonates with fans and reflects the spirit of the team is one of the challenges we need to address in this project.'
        },
        {
          heading: 'Ongoing Content Creation',
          description: 'Maintaining a consistent steady stream of high-quality graphics for social media and other online platforms to keep fans engaged and informed.'
        }
      ],
      solutions: [
        {
          heading: 'Innovative Design',
          description: 'Developed a modern and dynamic logo and jersey design that encapsulates the team’s energy and identity. This resonates well with the fans and has boosted team pride within the entire Infernos fraternity.'
        },
        {
          heading: 'Social Media Strategy',
          description: 'We are currently implementing a strategic approach to social media marketing through graphics design and advertising, ensuring regular and engaging content to boost fan interaction.'
        }
      ],
      mobileImage: '/assets/infernos_jersey.png',
      tabletImage: '/assets/infernos_schedule.png',
      impact: [
        'The new logo and jersey design have been well received by members of the team and the team\'s fanbase, enhancing the team’s brand image. Consistent and engaging social media graphics have increased the team’s online presence and fan engagement. The ongoing collaboration ensures that the team’s visual identity remains fresh and relevant.'
      ],
      techStackDescription: 'To provide high quality results, we utilize a variety of tools and techniques. Adobe Illustrator and Adobe Photoshop are used for creating detailed and scalable designs for the logo and jerseys. Canva and Adobe InDesign are employed for designing social media graphics, ensuring professional and visually appealing content. This combination of tools allows for flexibility and creativity in delivering outstanding visual assets for the team.',
      techStack: [
        { name: 'Adobe Illustrator', logo: '/assets/adobe_illustrator.svg' },
        { name: 'Adobe Photoshop', logo: '/assets/adobe_photoshop.svg' },
        { name: 'Canva', logo: '/assets/canva.svg' },
        { name: 'Adobe InDesign', logo: '/assets/adobe_indesign.svg' }
      ]
    },
    {
      id: 'mugeiyot',
      title: 'Mugeiyot Brand Enhancement & Social Media Project',
      industry: 'Retail & Hospitality',
      shortDescription: 'Designing social media posts and developing and executing a brand enhancement strategy for Mugeiyot Kenya Holdings Limited.',
      image: '/assets/mugeiyot_proj_long.gif',
      team: '2 Members',
      duration: 'Ongoing',
      launched: 'March 2024',
      rating: '5/5',
      laptopImage: '/assets/mugeiyot_mothers.webp',
      projectOverview: 'Mugeiyot Kenya Holdings Limited is a multi-business entity with supermarkets, a petrol station, and a restaurant. It requires a cohesive and engaging brand presence across its various services. Our ongoing efforts aim to elevate Mugeiyot’s brand identity and improve its connection with customers through an effective marketing strategy and providing consistent and creative social media content.',
      features: [
        'Social Media Content Creation',
        'Brand Enhancement Strategy',
        'Consistent Brand Identity',
        'Engaging Visual Content',
        'Multi-Business Brand Management'
      ],
      challenges: [
        {
          heading: 'Diverse Business Operations',
          description: 'Creating a unified brand identity that effectively represents the diverse services offered is the main challenge faced in this project.'
        },
        {
          heading: 'Regular Content Creation',
          description: 'Ensuring a steady flow of high-quality social media content to maintain customer engagement across different business segments.'
        }
      ],
      solutions: [
        {
          heading: 'Strategic Branding',
          description: 'Developed a brand enhancement strategy that aligns with Mugeiyot’s diverse business operations, ensuring a cohesive and recognizable brand identity.'
        },
        {
          heading: 'Creative Design',
          description: 'Utilized Canva and Adobe InDesign to produce visually appealing and engaging social media posts that capture the essence of Mugeiyot’s offerings.'
        }
      ],
      mobileImage: '/assets/mugeiyot_rlg.webp',
      tabletImage: '/assets/mugeiyot_holidays.webp',
      impact: [
        'There is improved brand recognition and customer loyalty brought about by consistent and strategic branding efforts. Engagement on social media platforms has also increased, leading to greater visibility and customer interaction. Our ongoing collaboration ensures that Mugeiyot’s brand remains relevant and appealing across all its business segments.'
      ],
      techStackDescription: 'The Mugeiyot brand enhancement and social media design project leverages Canva and Adobe InDesign to create high-quality, engaging visual content. Canva provides a user-friendly platform for designing social media posts, while Adobe InDesign allows for more advanced design capabilities. Together, these tools enable the creation of consistent and visually appealing content that enhances Mugeiyot’s online presence.',
      techStack: [
        { name: 'Canva', logo: '/assets/canva.svg' },
        { name: 'Adobe InDesign', logo: '/assets/adobe_indesign.svg' }
      ]
    },
    {
      id: 'taaimedia',
      title: 'Taai Media: Graphic Designs & Software Partnership',
      industry: 'Media & Entertainment',
      shortDescription: 'Since 2022, we provide innovative graphic designs and software solutions in partnership with Taai Media, our fellow Agents at Transfrontieres Agencies Limited.',
      image: '/assets/taai_proj_long.gif',
      team: '3 Members',
      duration: 'Ongoing',
      launched: 'March 2022',
      rating: '5/5',
      laptopImage: '/assets/taai_bizcard.png',
      projectOverview: 'Taai Media, a leading player in the media industry, has been in a dynamic partnership with us since 2022. Together, we have embarked on a journey of creativity and innovation, bringing forth compelling graphic designs and customised digital tools. Our collaboration has set new standards in the intersection of media, entertainment and technology.',
      features: [
        'Innovative Graphic Designs',
        'Tailored Software Tools',
        'Continuous Partnership & Support',
        'Event Collaborations'
      ],
      challenges: [
        {
          heading: 'Creative Vision Alignment',
          description: 'Aligning our creative vision with Taai Media’s brand identity and objectives to deliver designs that resonate with their audience.'
        },
        {
          heading: 'Software Integration',
          description: 'Ensuring seamless integration of software solutions into Taai Media’s existing infrastructure, enhancing efficiency and productivity.'
        }
      ],
      solutions: [
        {
          heading: 'Collaborative Creativity',
          description: 'Fostering a collaborative environment where ideas flow freely, resulting in innovative graphic designs that captivate and inspire.'
        },
        {
          heading: 'Tailored Software Solutions',
          description: 'Developing bespoke software tools tailored to Taai Media’s specific needs, providing streamlined workflows and enhanced performance.'
        }
      ],
      mobileImage: '/assets/taai_bizcardback.png',
      tabletImage: '/assets/taai_events.webp',
      impact: [
        'Our partnership has resulted in elevated brand presence and recognition through visually stunning graphic designs that leave a lasting impression on Taai Media’s audience. There has been increased operational efficiency and productivity through the implementation of customised software solutions designed to optimize workflows and processes. Continued collaboration and partnership fosters a culture of innovation, enabling both Ra\'Asis Software and Taai Media to stay ahead of the curve in the quest for digital growth.'
      ],
      techStackDescription: 'Leveraging a diverse set of tools and technologies to deliver outstanding results was important. We have been using the Adobe Creative Suite for graphic design and a variety of programming languages and frameworks to develop custom software solutions.',
      techStack: [
        { name: 'Adobe Illustrator', logo: '/assets/adobe_illustrator.svg' },
        { name: 'Adobe InDesign', logo: '/assets/adobe_indesign.svg' },
        { name: 'Canva', logo: '/assets/canva.svg' },
        { name: 'Java', logo: '/assets/java.svg' },
        { name: 'JavaScript', logo: '/assets/javascript.svg' },
      ]
    }
  ];
  
  export default caseStudiesData;
  
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import WhyChooseSection from '../WhyChooseSection';

function BackEnd() {

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Hire A Back-End Dev. | Ra'Asis",
    });
  });

  const handleLinkClick = (event) => {

    const linkText = event.target.textContent;

    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked A Btn Link',
      label: linkText,
    });
  };

  return (
    <>
      <Helmet>
        <title>Hire A Back-End Dev. | Ra'Asis</title>
        <meta name="description" content="Behind every great user interface lies a robust Back-End that powers it. Our Back-End developers are the backbone of your application, ensuring it runs smoothly, securely and efficiently." />
        <meta property="og:title" content="Hire A Back-End Developer | Ra'Asis" />
        <meta property="og:description" content="Our Back-End developers are the backbone of your application, ensuring it runs smoothly, securely and efficiently." />
        <meta property="og:url" content="https://raasissoftware.com/hire-a-developer/back-end" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Hire A Back-End Developer | Ra'Asis" />
        <meta name="twitter:description" content="Behind every great user interface lies a robust Back-End that powers it. Our Back-End developers are the backbone of your application." />
        <meta name="twitter:image" content="/assets/raasis_logo.svg" />
      </Helmet>
      <section className="about-introduction hire-dev-intro">
      <div className="about-intro-left hire-dev-left">
        <h4><Link to='/'><span>Home</span></Link> / <Link to='/hire-a-developer'>Hire A Developer</Link> / Back-End</h4>
        <div className='hire-dev-headings'>
          <h3>A Back-End Developer For Your Project</h3>
          <h1>Ensure Your Software Application Is Robust, Secure & Performs Seamlessly</h1>
        </div>
        <div className='hire-dev-text'>
          <p>Behind every great user interface lies a robust Back-End that powers it. Our Back-End developers are the backbone of your application, ensuring it runs smoothly, securely, and efficiently. With expertise in server-side languages, databases, and APIs, they build the logic and infrastructure that supports your Front-End. Choose our Back-End developers for an intuitive  application and guaranteed optimal performance.</p>
        </div>
      </div>
      <div className="about-intro-right hire-dev-right">
        <div className='hire-dev-box'>
          <div className='hire-dev-box-cont'>
            <div className='stack-icon'>
              <i className='material-symbols-sharp'>flip_to_back</i>
            </div>
            <h3>A Single<br />Back-End<br />Developer</h3>
            <h3>Rate Is<br /><br /><span id='larger'>$12/</span><span id='smaller'>Hr</span></h3>
          </div>
          <div className='hire-dev-btn'>
              <Link to="/contact" onClick={handleLinkClick}>Get Back-End Dev.</Link>
          </div>
        </div>
        <div className='not-decided'>
          <p>Haven't made up your mind yet on what your project requires ?</p>
          <Link to='/contact' onClick={handleLinkClick}>Click here to book a free consultation</Link>
        </div>
      </div>
    </section>

    <WhyChooseSection />
  </>
  );
}

export default BackEnd;